import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import { ReactComponent } from 'typings/common/react';
import { LoginData } from 'typings/api/user';
import { ErrorResponse } from 'typings/api/common';

import { loginFormFields } from 'constants/authFormFields';

import { useLoginUserMutation } from 'api/usersSlice';

import { setCredentials } from 'store/authSlice';

import { useAppDispatch } from 'util/hooks/hooks';

import FormField from 'components/form/FormField';
import AuthBase from './AuthBase';
import ErrorBlock from 'components/common/ErrorBlock';

const LoginForm: ReactComponent = () => {
  const methods = useForm();

  const dispatch = useAppDispatch();

  const [logInUser, status] = useLoginUserMutation();

  const { isError, isLoading, error } = status;

  const onLogin = async (data: FieldValues) => {
    try {
      const returned = await logInUser(data as LoginData).unwrap();

      if (!!returned) {
        if (data.remember_me) {
          localStorage.setItem('user', JSON.stringify(returned));
        }
        sessionStorage.setItem('user', JSON.stringify(returned));
        dispatch(setCredentials(returned));
      }
    } catch (err) {}
  };

  return (
    <FormProvider {...methods}>
      <AuthBase onSubmit={onLogin} isDisabled={isLoading}>
        {isError && <ErrorBlock errors={error as ErrorResponse} />}
        {loginFormFields.map(({ name, label, type }, idx) => (
          <FormField
            key={`login-field-${idx}`}
            type={type}
            label={label}
            name={name}
            register={methods.register}
          />
        ))}
      </AuthBase>
    </FormProvider>
  );
};

export default LoginForm;
