import { Tab } from '@headlessui/react';
import { Container } from 'components/common/Container';

import { useSearchParams } from 'react-router-dom';

import { useGetInstallationsQuery } from 'api/installationsSlice';

import { setDefaultTab } from 'util/tableHelpers';

import TabHeader from 'components/common/TabHeader';
import LoadingSkeleton from 'components/common/LoadingSkeleton';

import GroupPanel from './GroupPanel';

const Groups = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useGetInstallationsQuery();

  const urlTab = parseInt(searchParams.get('tab') || '1');

  return (
    <Container
      className="pt-12 flex justify-between md:px-0 z-0 relative"
      withPadding={false}
    >
      <div className="w-full">
        {data?.data && (
          <Tab.Group
            defaultIndex={setDefaultTab(data?.data || [], urlTab)}
            onChange={(e: number) =>
              data?.data &&
              setSearchParams({ tab: data?.data[e].id.toString() })
            }
          >
            <div className="flex pb-2 flex-col lg:flex-row lg:justify-between">
              <TabHeader data={data?.data} />
            </div>
            <Tab.Panels>
              {data?.data?.map((installation, idx) => (
                <Tab.Panel key={`table-${idx}`}>
                  <GroupPanel installation={installation} />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        )}
        {!data?.data && <LoadingSkeleton />}
      </div>
    </Container>
  );
};

export default Groups;
