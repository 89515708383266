import { ButtonHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

import { ClassNamesProp } from 'typings/common/react';

const btnVariants = {
  skinny: {
    disabled: 'h-8 rounded text-white px-8 duration-300 bg-purple-disabled',
    enabled:
      'h-8 rounded text-white px-8 duration-300 bg-purple-light hover:bg-purple-dark',
  },
  default: {
    disabled:
      'text-white px-4 py-2 h-min rounded duration-300 bg-purple-disabled',
    enabled:
      'text-white px-4 py-2 h-min rounded duration-300 hover:bg-purple-dark bg-purple-light',
  },
};

interface Props extends ClassNamesProp {
  onClick?: ((e?: Event) => void) | null;
  disabled?: boolean;
  type?: 'submit' | 'button';
  variant?: 'default' | 'skinny';
}

type ButtonProps = Props & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick,
      children,
      className,
      disabled = false,
      type = 'button',
      variant = 'default',
    },
    ref
  ) => {
    const classes = clsx(
      className || btnVariants[variant][disabled ? 'disabled' : 'enabled']
    );
    return (
      <button
        ref={ref}
        className={classes}
        onClick={() => onClick && onClick()}
        type={type}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
);
export default Button;
