import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { usePagination, useTable } from 'react-table';

import { useGetInstallationAttributeGroupsQuery } from 'api/installationsSlice';
import { useGetLogsQuery } from 'api/logsSlice';

import useRenderTableCell from 'util/hooks/useRenderTableCell';

import { logsTableColumns } from 'constants/logsColumns';

import { Log } from 'typings/api/logs';
import { ReactComponent } from 'typings/common/react';
import { ResponseMeta } from 'typings/api/common';
import { Installation } from 'typings/entity/installations';

import LoadingSkeleton from 'components/common/LoadingSkeleton';
import Table from 'components/Table/Table';

interface Props {
  installation: Installation;
}

const defaultValues: { search: string; logs: Log[]; meta?: ResponseMeta } = {
  search: '',
  logs: [],
};

const LogsPanel: ReactComponent<Props> = ({ installation }) => {
  const { data: attributeGroupData, isLoading: attributeGroupIsLoading } =
    useGetInstallationAttributeGroupsQuery(installation.id);

  const methods = useForm<typeof defaultValues>({
    defaultValues,
  });
  const { control, register, reset } = methods;

  const renderTableCell = useRenderTableCell({
    register,
    name: 'logs',
    installation,
  });
  const formValues = useWatch({ control });

  const tableProps = useTable(
    {
      columns: logsTableColumns as any,
      data: formValues.logs || [],
      defaultColumn: { Cell: renderTableCell },
      pageCount: formValues.meta?.last_page || 1,
      manualPagination: true,
    },
    usePagination
  );

  const { data, isLoading, isFetching } = useGetLogsQuery({
    id: installation.id,
    search: formValues.search || '',
    page: tableProps.state.pageIndex + 1,
  });

  useEffect(() => {
    if (data && attributeGroupData) {
      const result = data.data.map(item => {
        const attributeGroup = attributeGroupData.find(
          attrGroup => attrGroup.value === Number(item.attribute_group)
        )?.label;

        return {
          ...item,
          attribute_group: attributeGroup
            ? `${attributeGroup} (${item.attribute_group})`
            : item.attribute_group,
        };
      });

      reset({
        search: formValues.search,
        logs: isFetching || isLoading ? [] : result,
        meta: data?.meta,
      });
    }
  }, [
    isLoading,
    data,
    attributeGroupIsLoading,
    attributeGroupData,
    reset,
    formValues.search,
    isFetching,
  ]);

  return (
    <FormProvider {...methods}>
      {!isLoading ? (
        <Table meta={data?.meta} name="logs" tableProps={tableProps} />
      ) : (
        <LoadingSkeleton />
      )}
    </FormProvider>
  );
};

export default LogsPanel;
