import { NavLink } from 'typings/router';

import LoginForm from 'views/Auth/LoginForm';
import RegisterForm from 'views/Auth/RegisterForm';
import ResetPassword from 'views/Auth/ResetPassword';
import Brands from 'views/Brands/Brands';
import Dashboard from 'views/Dashboard/Dashboard';
import Groups from 'views/Groups/Groups';
import JobRunner from 'views/JobRunner/JobRunner';
import Logs from 'views/Logs/Logs';
import Configuartion from 'views/Configuration/Configuration';
import Clients from 'views/Clients/Clients';

export const authRoutes: NavLink[] = [
  { path: '/login', component: LoginForm },
  { path: '/register', component: RegisterForm },
  { path: '/reset-password/:token', component: ResetPassword },
  { path: '/reset-password', component: ResetPassword },
];

const routes: NavLink[] = [
  {
    path: '/dashboard',
    component: Dashboard,
    navBarLink: true,
    name: 'navigation.dashboard.name',
  },
  {
    path: '/groups',
    component: Groups,
    navBarLink: true,
    name: 'navigation.groups.name',
  },
  {
    path: '/brands',
    component: Brands,
    navBarLink: true,
    name: 'navigation.brands.name',
  },
  {
    path: '/clients',
    component: Clients,
    navBarLink: true,
    name: 'navigation.clients.name',
  },
  {
    path: '/jobRunner',
    component: JobRunner,
    navBarLink: true,
    name: 'navigation.jobRunner.name',
  },
  {
    path: '/configuration',
    component: Configuartion,
    navBarLink: true,
    name: 'navigation.configuration.name',
  },
  {
    path: '/logs',
    component: Logs,
    navBarLink: true,
    name: 'navigation.logs.name',
  },
];

export const combinedRoutes: NavLink[] = [...routes];
