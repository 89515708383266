import {
  CommandPayload,
  JobCommandsResponse,
  QueuedJob,
} from 'typings/api/jobRunner';
import {
  BasicResponse,
  DefaultQueryParams,
  GenericResponse,
} from 'typings/api/common';
import { apiSlice } from './apiSlice';

export const jobRunnerSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getJobsQueue: builder.query<
      GenericResponse<QueuedJob[]>,
      DefaultQueryParams
    >({
      query: ({ search, page }) => ({
        url: '/commands/queue',
        method: 'GET',
        params: { search, page: !!search ? 1 : page },
      }),
      providesTags: ['Job'],
    }),
    getRunnableJobs: builder.query<JobCommandsResponse[], void>({
      query: () => ({ url: '/commands', method: 'GET' }),
    }),
    runCommand: builder.mutation<BasicResponse, CommandPayload>({
      query: ({ data, command }) => ({
        url: `/commands/run/${command}`,
        method: 'POST',
        body: { parameters: data },
      }),
      invalidatesTags: ['Job'],
    }),
  }),
});

export const {
  useGetJobsQueueQuery,
  useGetRunnableJobsQuery,
  useRunCommandMutation,
} = jobRunnerSlice;
