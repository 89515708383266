import {
  BaseQueryApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query';

import { logOut } from 'store/authSlice';
import { RootState } from 'store/store';

const baseUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).authUser?.access_token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    return headers;
  },
});

const baseQueryWithRefreshing = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  options: {}
) => {
  let result = await baseQuery(args, api, options);

  if (result.error?.status === 401) {
    api.dispatch(logOut());
  }
  return result;
};

export default baseQueryWithRefreshing;
