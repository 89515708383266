import { isFulfilled, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

export const rtkQueryResponseMiddleware: Middleware = () => next => action => {
  if (isFulfilled(action) && action.type.includes('executeMutation')) {
    const successMessage = action.payload.response || action.payload.message;
    successMessage && toast.success(successMessage);
  }
  if (isRejectedWithValue(action)) {
    toast.error(action.payload.data.message);
  }

  return next(action);
};
