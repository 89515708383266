import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dictionary, ReactComponent } from 'typings/common/react';
import { AppConfiguration } from 'typings/entity/configuration';

import { SelectOption } from 'typings/form/form';

import {
  useGetConfigurationsQuery,
  useUpdateConfigurationsMutation,
} from 'api/configurationSlice';
import { useGetInstallationParamsQuery } from 'api/installationsSlice';

import {
  assembleConfigurationsPayload,
  attachValuesToConfigurationsData,
  parseConfigurationsToGroups,
} from 'util/parsingHelpers';

import FormField from 'components/form/FormField';
import LoadingSkeleton from 'components/common/LoadingSkeleton';
import Button from 'components/common/Button';

import ConfigDisclosure from './ConfigDisclosure';
import ConfigNestedFieldsBlock from './ConfigNestedFieldsBlock';

interface Props {
  id: number;
}

const ConfigPanel: ReactComponent<Props> = ({ id }) => {
  const { data } = useGetConfigurationsQuery({ id, search: '', page: 1 });
  const { data: paramsData } = useGetInstallationParamsQuery(id);
  const { t } = useTranslation();

  const methods = useForm<FieldValues>();
  const [updateTrigger, updateStatus] = useUpdateConfigurationsMutation();
  const {
    reset,
    handleSubmit,
    formState: { dirtyFields },
    setError,
  } = methods;

  const [fieldGroups, setFieldGroups] = useState<
    Dictionary<AppConfiguration[]>
  >({});

  useEffect(() => {
    if (data && paramsData) {
      const values = attachValuesToConfigurationsData(data.data, paramsData);

      const res = parseConfigurationsToGroups(values);

      setFieldGroups(res);

      const defaultValues = values.reduce((acc, item) => {
        if (item.type === 'nested') {
          const typedFields = (item.value as Dictionary<string>[]).map(item => {
            const [dictItem] = Object.entries(item);
            return { [`nested.${dictItem[0]}`]: dictItem[1] };
          });
          return { ...acc, ...Object.assign({}, ...typedFields) };
        }
        return { ...acc, [item.configuration.code]: item.value };
      }, {});

      reset(defaultValues);
    }
  }, [data, setFieldGroups, paramsData, reset]);

  const updateConfigurations = (formValues: FieldValues) => {
    const updatedData = data?.data.filter(
      item => !!dirtyFields[item.configuration.code] || !!dirtyFields[item.type]
    );

    const payload = assembleConfigurationsPayload(
      updatedData || [],
      formValues
    );
    updateTrigger(payload || [])
      .unwrap()
      .catch(err => {
        Object.keys(err.data.errors).forEach(error => {
          const errorMessage = err.data.errors[error].join(', ');
          const idx = parseInt(error.split('.')[1]);
          const payloadItem = !isNaN(idx) ? payload[idx] : null;

          if (payloadItem?.type === 'nested') {
            (payloadItem.value as Dictionary<string>[]).forEach(element => {
              if (!element[Object.keys(element)[0]]?.length) {
                setError(element.name, { message: errorMessage });
              }
            });
          } else if (payloadItem?.name) {
            setError(payloadItem.name, { message: errorMessage });
          }
        });
      });
  };

  return paramsData && fieldGroups ? (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-4 my-4 rounded-md"
        onSubmit={handleSubmit(updateConfigurations)}
      >
        <ConfigDisclosure
          buttonText={t('configuration.name.general')}
          openByDefault={true}
        >
          {fieldGroups['general']?.map(
            (
              {
                configuration: { description, code, function: func, name },
                type,
                value,
              },
              idx
            ) =>
              type === 'nested' ? (
                <ConfigNestedFieldsBlock
                  items={value as Dictionary<string>[]}
                  description={description}
                  key={`nested-general-${idx}`}
                />
              ) : (
                <FormField
                  label={name}
                  name={code}
                  register={methods.register}
                  type={type}
                  key={`general-${idx}`}
                  options={
                    type === 'creatableMulti'
                      ? (value as SelectOption[])
                      : paramsData?.[func || '']
                  }
                />
              )
          )}
        </ConfigDisclosure>
        <ConfigDisclosure buttonText={t('configuration.name.birthday')}>
          {fieldGroups['birthday']?.map(
            (
              {
                configuration: { description, code, function: func, name },
                type,
                value,
              },
              idx
            ) =>
              type === 'nested' ? (
                <ConfigNestedFieldsBlock
                  items={value as Dictionary<string>[]}
                  description={description}
                  key={`nested-birthday-${idx}`}
                />
              ) : (
                <FormField
                  label={name}
                  name={code}
                  register={methods.register}
                  type={type}
                  key={`birthday-${idx}`}
                  options={
                    type === 'creatableMulti'
                      ? (value as SelectOption[])
                      : paramsData?.[func || '']
                  }
                />
              )
          )}
        </ConfigDisclosure>
        <ConfigDisclosure buttonText={t('configuration.name.order')}>
          {fieldGroups['order']?.map(
            (
              {
                configuration: { description, code, function: func, name },
                type,
                value,
              },
              idx
            ) =>
              type === 'nested' ? (
                <ConfigNestedFieldsBlock
                  items={value as Dictionary<string>[]}
                  description={name}
                  key={`nested-order-${idx}`}
                />
              ) : (
                <FormField
                  label={description}
                  name={code}
                  register={methods.register}
                  type={type}
                  key={`order-${idx}`}
                  options={
                    type === 'creatableMulti'
                      ? (value as SelectOption[])
                      : paramsData?.[func || '']
                  }
                />
              )
          )}
        </ConfigDisclosure>
        <div className="ml-auto">
          <Button type="submit" disabled={updateStatus.isLoading}>
            {t('actions.common.saveChanges')}
          </Button>
        </div>
      </form>
    </FormProvider>
  ) : (
    <LoadingSkeleton />
  );
};

export default ConfigPanel;
