import { Fragment, useState } from 'react';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTable } from 'react-table';

import { Dictionary, ReactComponent } from 'typings/common/react';
import { DashbaordWidgetTypes } from 'typings/entity/dashboard';
import { DashboardItem } from 'typings/api/dashboard';
import { Installation } from 'typings/entity/installations';

import SimpleTable from 'components/Table/SimpleTable';
import Button from 'components/common/Button';
import TabHeader from 'components/common/TabHeader';

interface Props {
  headerText: string;
  failureText: string;
  redirectPath?: string;
  data: Dictionary<DashboardItem<DashbaordWidgetTypes>>;
  columns: any;
  installations: Installation[];
}
const DashboardWidget: ReactComponent<Props> = ({
  redirectPath,
  data,
  columns,
  headerText,
  failureText,
  installations,
}) => {
  const totalItemCount = data.count;
  const x = Object.entries(data).find(item => item[1]?.count > 0);
  const tabWithErrorsIndex = x?.[0] ? parseInt(x?.[0]) : 1;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(tabWithErrorsIndex);
  const borderStyles = clsx('border-2 rounded', {
    'border-danger-light': !!totalItemCount,
    'border-success': !totalItemCount,
  });
  const tableContainerClasses = clsx('mx-8', {
    'flex flex-col justify-center text-center h-80':
      data[selectedTab].items.length === 0,
  });
  const dataCount = data[selectedTab].count - data[selectedTab].items.length;

  const tableProps = useTable({
    columns,
    data: data[selectedTab].items as any,
  });

  return (
    <div className={borderStyles}>
      <div className="flex justify-around items-center p-4 bg-white text-md">
        <h1>{t(`dashboard.${headerText}`)}</h1>
        {!!totalItemCount && (
          <p className="col-start-4 col-span-3 items-center text-danger-dark">
            {t(failureText, { totalItemCount })}
          </p>
        )}
      </div>
      {!!totalItemCount ? (
        <>
          <Tab.Group
            onChange={(e: number) => setSelectedTab(installations[e].id || 1)}
            defaultIndex={tabWithErrorsIndex - 1}
          >
            <TabHeader
              data={installations}
              variant="small"
              dashboardData={data}
            />
          </Tab.Group>
          <div className={tableContainerClasses}>
            {data[selectedTab].items.length ? (
              <SimpleTable tableProps={tableProps} />
            ) : (
              <p className="text-success text-h1 ">
                {t('dashboard.configured')}
              </p>
            )}

            <div className="flex justify-end py-4">
              {redirectPath && data[selectedTab].items.length > 0 && (
                <Button
                  className="underline underline-offset-4 hover:text-purple-light duration-300"
                  onClick={() => navigate(`${redirectPath}?tab=${selectedTab}`)}
                >
                  {dataCount > 0
                    ? t('dashboard.show_entries', { dataCount })
                    : t('dashboard.show_all')}
                </Button>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="py-12 flex justify-center">
          <h1 className="text-h1 text-success">{t('dashboard.no_issues')}</h1>
        </div>
      )}
    </div>
  );
};
export default DashboardWidget;
