import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FieldError,
  FieldValues,
  useFormContext,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';

import { ReactComponent } from 'typings/common/react';
import { ConfigurationGroup, ValueTypes } from 'typings/api/configurations';
import { FormFieldType, InputBoxType, SelectOption } from 'typings/form/form';
import { Installation } from 'typings/entity/installations';

import { useAppSelector } from 'util/hooks/hooks';
import { getDisabledValue } from 'util/tableHelpers';

import Input from './Input';
import Checkbox from './Checkbox';
import Select from './Select';

interface Props {
  type: FormFieldType;
  name: string;
  label: string;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
  isDisabled?:
    | ((formValues: FieldValues, installationConfig?: Installation) => boolean)
    | boolean;
  installation?: Installation;
  searchUrl?: string;
  options?: SelectOption[];
  setValueFromConfigurations?:
    | ValueTypes
    | ((configurations: ConfigurationGroup[]) => void);
  blacklistedSelectValue?: string;
  isAsync?: Boolean;
}

const FormField: ReactComponent<Props> = ({
  type,
  name,
  label,
  register,
  setValueFromConfigurations,
  installation,
  required = false,
  isDisabled = null,
  searchUrl = '',
  options = [],
  blacklistedSelectValue = '',
  isAsync= false,
}) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const formValues = useWatch({ control });
  const [group, index, fieldName, fieldIndex] = name.split('.');
  const fieldDisabled = getDisabledValue(
    isDisabled,
    formValues,
    group,
    index,
    installation
  );

  const fieldValue = getValues(name);

  const { configurations } = useAppSelector(state => state.configs);

  const errorsArray = errors?.[group as keyof typeof errors];
  const errorsObject = errorsArray?.[index as keyof typeof errorsArray];
  const errorData =
    (group === 'nested' && errorsObject) ||
    (fieldName === 'config' &&
      errorsObject?.[fieldName as keyof typeof errorsObject]?.[fieldIndex]) ||
    errorsObject?.[fieldName as keyof typeof errorsObject] ||
    errors[name];

  const disallowedValues = [
    configurations.find(
      item => item.configuration.code === blacklistedSelectValue
    )?.value,
  ]
    .flat()
    .filter((value): value is number => typeof value === 'number');

  useEffect(() => {
    if (fieldDisabled && fieldValue) {
      setValue(name, null);
    }
  }, [fieldDisabled, fieldValue, name, setValue]);

  useEffect(() => {
    const value = getValues(name);
    const valueisPresent = value !== 'undefined' && value !== null;

    if (!fieldDisabled && setValueFromConfigurations && !valueisPresent) {
      const isFunction = typeof setValueFromConfigurations === 'function';
      setValue(
        name,
        isFunction
          ? setValueFromConfigurations(configurations)
          : setValueFromConfigurations
      );
    }
  }, [
    configurations,
    fieldDisabled,
    getValues,
    name,
    setValue,
    setValueFromConfigurations,
  ]);

  return (
    <>
      {['text', 'password', 'email', 'number'].includes(type) && (
        <Input
          label={t(label)}
          type={type as InputBoxType}
          name={name}
          register={register}
          required={required}
          isDisabled={fieldDisabled}
          error={errorData as FieldError}
        />
      )}
      {type === 'checkbox' && (
        <Checkbox
          name={name}
          label={t(label)}
          register={register}
          required={required}
          isDisabled={fieldDisabled}
          error={errorData as FieldError}
        />
      )}
      {(type === 'select' ||
        type === 'multiselect' ||
        type === 'creatableMulti') && (
        <Select
          name={name}
          isMulti={type === 'multiselect' || type === 'creatableMulti'}
          label={t(label)}
          required={required}
          searchUrl={searchUrl}
          installationId={installation?.id || 1}
          options={options}
          creatable={type === 'creatableMulti'}
          isDisabled={fieldDisabled}
          error={errorData as FieldError}
          blacklistedValues={disallowedValues}
          isAsync={isAsync}
        />
      )}
    </>
  );
};

export default FormField;
