import { DefaultQueryParams, GenericResponse } from 'typings/api/common';
import {
  ConfigurationGroup,
  ConfigurationPayload,
} from 'typings/api/configurations';
import { BaseResponse } from 'typings/api/user';
import { apiSlice } from './apiSlice';

export const configurationSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getConfigurations: builder.query<
      GenericResponse<ConfigurationGroup[]>,
      DefaultQueryParams
    >({
      query: ({ id, search, page }) => ({
        url: `/configurations`,
        method: 'GET',
        params: { search, page: !!search ? 1 : page, installation: id },
      }),
      providesTags: ['Configuration'],
    }),
    updateConfigurations: builder.mutation<
      BaseResponse,
      ConfigurationPayload[]
    >({
      query: configurations => ({
        url: '/configurations',
        method: 'POST',
        body: { configurations },
      }),
    }),
  }),
});

export const { useGetConfigurationsQuery, useUpdateConfigurationsMutation } =
  configurationSlice;
