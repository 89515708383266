import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoginResponse } from 'typings/api/user';
import { AuthSlice } from 'typings/store';

import { RootState } from './store';

const initialState: AuthSlice = {
  user: null,
  access_token: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, access_token } }: PayloadAction<LoginResponse>
    ) => {
      state.user = user;
      state.access_token = access_token;
    },
    logOut: state => {
      state.access_token = null;
      state.user = null;

      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
    },
    setRefreshToken: (
      state,
      { payload: refreshToken }: PayloadAction<string>
    ) => {
      state.access_token = refreshToken;
      const user = localStorage.getItem('user');

      if (user) {
        const userObj = JSON.parse(user);
        userObj.access_token = refreshToken;
        localStorage.setItem('user', JSON.stringify(userObj));
      }
    },
  },
});

export const { setCredentials, logOut, setRefreshToken } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.authUser.user;
