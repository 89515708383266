import {
  BasicResponse,
  DefaultQueryParams,
  GenericResponse,
} from 'typings/api/common';
import { Group, InvertedGroup, UpdateGroupResponse } from 'typings/api/groups';
import { parseGroupsToTableData } from 'util/parsingHelpers';

import { apiSlice } from './apiSlice';

const groupsSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getGroups: builder.query<GenericResponse<Group[]>, DefaultQueryParams>({
      query: ({ id, search, page }) => ({
        url: `/groups`,
        method: 'GET',
        params: { search, page: !!search ? 1 : page, installation: id },
      }),
      transformResponse: (
        response: GenericResponse<Group[]>
      ): GenericResponse<Group[]> => {
        return {
          meta: response.meta,
          data: parseGroupsToTableData(response.data),
        };
      },
      providesTags: ['Group'],
    }),
    updateGroup: builder.mutation<UpdateGroupResponse, InvertedGroup[]>({
      query: payload => ({
        url: '/groups',
        method: 'POST',
        body: { groups: payload },
      }),
    }),
    deleteGroup: builder.mutation<BasicResponse, number>({
      query: (id: number) => ({
        url: `/groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Group'],
    }),
    checkGroups: builder.mutation<BasicResponse, void>({
      query: () => ({
        url: '/commands/run/checkGroups',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useDeleteGroupMutation,
  useCheckGroupsMutation,
  useUpdateGroupMutation,
} = groupsSlice;
