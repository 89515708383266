import { Tab } from '@headlessui/react';

import { useGetInstallationsQuery } from 'api/installationsSlice';

import TabHeader from 'components/common/TabHeader';
import { Container } from 'components/common/Container';

import ClientPanel from './ClientPanel';

const Clients = () => {
  const { data, isLoading } = useGetInstallationsQuery();

  return (
    <Container
      className="pt-12 flex justify-between md:px-0 z-0 relative"
      withPadding={false}
    >
      {!isLoading && (
        <div className="w-full">
          <Tab.Group>
            <div className="flex pb-2 flex-col lg:flex-row lg:justify-between">
              <TabHeader data={data?.data.filter(item => item.hasClients)} />
            </div>
            <Tab.Panels>
              {data?.data
                .filter(item => item.hasClients)
                .map((installation, idx) => (
                  <Tab.Panel key={`table-${idx}`}>
                    <ClientPanel installation={installation} />
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      )}
    </Container>
  );
};

export default Clients;
