import { FormField } from 'typings/form/form';

export const loginFormFields: FormField[] = [
  {
    type: 'email',
    label: 'form.email',
    name: 'email',
  },
  {
    type: 'password',
    label: 'form.password',
    name: 'password',
  },
  {
    type: 'checkbox',
    label: 'form.remember_me',
    name: 'remember_me',
  },
];

export const registerFormFields: FormField[] = [
  { type: 'text', label: 'form.name', name: 'name', required: true },
  { type: 'email', label: 'form.email', name: 'email', required: true },
  {
    type: 'password',
    label: 'form.password',
    name: 'password',
    required: true,
  },
  {
    type: 'password',
    label: 'form.password_confirmation',
    name: 'password_confirmation',
    required: true,
  },
];

export const resetPasswordFields: FormField[] = [
  { type: 'email', label: 'form.email', name: 'email', required: true },
];

export const changePasswordFields: FormField[] = [
  {
    type: 'password',
    label: 'form.new_password',
    name: 'password',
    required: true,
  },
  {
    type: 'password',
    label: 'form.confirm_new_password',
    name: 'password_confirmation',
    required: true,
  },
];
