import { TableInstance } from 'react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ResponseMeta } from 'typings/api/common';

import ConfirmationModal from 'components/common/ConfirmationModal';
import Button from 'components/common/Button';
import Pagination from './Pagination';

import { ReactComponent as Trash } from 'assets/svg/trash.svg';

interface ReactTableProps {
  deleteFn?: (param: number) => void;

  name: string;
  meta?: ResponseMeta;
  tableProps: TableInstance<object>;
}

interface RowItems {
  is_active?: boolean;
  id?: number;
  if_error?: boolean;
}

const Table = ({ deleteFn, name, meta, tableProps }: ReactTableProps) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState({ state: false, id: 0 });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
  } = tableProps!;

  const paginationProps = {
    pageIndex,
    nextPage,
    previousPage,
    gotoPage,
    canNextPage,
    canPreviousPage,
    pageCount: meta?.last_page || 1,
  };

  return (
    <>
      <div className="pb-2 overflow-x-scroll">
        <table
          className="w-full text-sm text-left text-gray-500 table-auto border-spacing-y-4 border-separate "
          {...getTableProps()}
        >
          <thead className="text-gray-200 uppercase bg-gray-light">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <th
                      {...column.getHeaderProps()}
                      scope="col"
                      className="py-3 px-3"
                    >
                      {t(column.render('header') as string)}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} className="overflow-y-visible">
            {page.map((row, i) => {
              prepareRow(row);
              const originalRow: RowItems = row.original;

              const buttonClasses = clsx('my-2 text-white p-2 rounded ', {
                'bg-danger-light hover:bg-danger-dark duration-300':
                  !Object.keys(originalRow).includes('is_active') ||
                  originalRow?.is_active,
                'bg-danger-disabled': !originalRow?.is_active,
              });

              return (
                <tr
                  {...row.getRowProps()}
                  className="z-0 bg-white"
                  key={`row-${i}-${pageIndex}`}
                >
                  <>
                    {row.cells.map((cell, idx) => {
                      const className = clsx(
                        'px-3 py-2 first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg',
                        {
                          'border-2 border-danger-light first:border-l-2 last:border-r-2 border-l-0 border-r-0':
                            originalRow.if_error === true,
                        }
                      );
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={className}
                          key={`cell-${idx}-row-${i}-page-${pageIndex}`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}

                    {deleteFn && (
                      <td className="pr-6 last:rounded-tr-lg last:rounded-br-lg">
                        <Button
                          className={buttonClasses}
                          disabled={!originalRow?.is_active ?? false}
                          onClick={() =>
                            setModalOpen({
                              state: true,
                              id: originalRow.id!,
                            })
                          }
                        >
                          <Trash />
                        </Button>
                      </td>
                    )}
                  </>
                </tr>
              );
            })}
          </tbody>
        </table>
        {meta && <Pagination {...paginationProps} meta={meta} />}
      </div>
      {deleteFn && (
        <ConfirmationModal
          isOpen={modalOpen}
          setOpen={setModalOpen}
          title={`actions.${name}.deleteTitle`}
          onConfirm={deleteFn}
        />
      )}
    </>
  );
};

export default Table;
