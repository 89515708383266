import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import {
  FieldValues,
  FormProvider,
  useForm,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { usePagination, useTable } from 'react-table';

import {
  useCheckGroupsMutation,
  useDeleteGroupMutation,
  useGetGroupsQuery,
  useUpdateGroupMutation,
} from 'api/groupsSlice';
import { useGetConfigurationsQuery } from 'api/configurationSlice';

import { ReactComponent } from 'typings/common/react';
import { Installation } from 'typings/entity/installations';
import { Group } from 'typings/api/groups';
import { ResponseMeta } from 'typings/api/common';

import { groupsTableColumns } from 'constants/groupsColumns';

import useRenderTableCell from 'util/hooks/useRenderTableCell';
import { parseGroupsDataToApiData } from 'util/parsingHelpers';
import { findErrorsfromBrandsPayload } from 'util/errorHelpers';

import LoadingSkeleton from 'components/common/LoadingSkeleton';
import Table from 'components/Table/Table';
import TableButtons from 'components/Table/TableButtons';
import Input from 'components/form/Input';

interface Props {
  installation: Installation;
}

const defaultValues: { search: string; groups: Group[]; meta?: ResponseMeta } =
  {
    search: '',
    groups: [],
  };

const GroupPanel: ReactComponent<Props> = ({ installation }) => {
  const [deleteGroup] = useDeleteGroupMutation();

  const { id } = installation;

  const { t } = useTranslation();

  const methods = useForm<typeof defaultValues>({
    defaultValues,
  });

  const [updateGroup, updateStatus] = useUpdateGroupMutation();
  const [runGroupsCheck, runCheckStatus] = useCheckGroupsMutation();

  const { control, register, reset, handleSubmit, setError } = methods;

  const formValues = useWatch({ control });
  const { dirtyFields } = useFormState({ control });

  const renderTableCell = useRenderTableCell({
    register,
    name: 'groups',
    installation,
  });

  const tableProps = useTable(
    {
      columns: groupsTableColumns as any,
      data: formValues.groups || [],
      defaultColumn: { Cell: renderTableCell },
      pageCount: formValues.meta?.last_page || 1,
      manualPagination: true,
    },
    usePagination
  );
  const { data, isLoading, isFetching, refetch } = useGetGroupsQuery({
    id,
    search: formValues.search || '',
    page: tableProps.state.pageIndex + 1,
  });

  const { isLoading: configsLoading } = useGetConfigurationsQuery({
    id,
    page: 1,
    search: '',
  });

  const onSubmit = async (formData: FieldValues) => {
    const fieldsToParse = dirtyFields.groups
      ?.map((_, idx) => idx)
      ?.filter(item => typeof item === 'number');

    const payload = parseGroupsDataToApiData(
      formData.groups,
      fieldsToParse || []
    );
    updateGroup(payload || [])
      .unwrap()
      .then(() => refetch())
      .catch(err => {
        const appErrors = findErrorsfromBrandsPayload(
          err.data.errors,
          payload || []
        );
        appErrors.forEach(item => {
          if (item) {
            const [keyValuePair] = Object.entries(item);

            setError(keyValuePair[0] as keyof typeof formValues, {
              message: keyValuePair[1],
            });
          }
        });
      });
  };

  useEffect(() => {
    reset({
      search: formValues.search,
      groups: isFetching || isLoading ? [] : data?.data,
      meta: data?.meta,
    });
  }, [data?.data, formValues.search, data?.meta, isFetching, isLoading, reset]);

  return (
    <FormProvider {...methods}>
      <Input
        className="w-full h-10 rounded-md mt-6 shadow-sm pl-2 placeholder:text-md md:mt-12"
        placeholder={t('global.search')!}
        register={register}
        name="search"
        type="search"
      />
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <TableButtons
          disabled={runCheckStatus.isLoading || updateStatus.isLoading}
          checkActionName="actions.groups.runACheck"
          checkAction={runGroupsCheck}
        />
        {!isLoading &&
        tableProps.state.pageIndex >= 0 &&
        !isFetching &&
        !configsLoading ? (
          <Table
            meta={data?.meta}
            deleteFn={deleteGroup}
            tableProps={tableProps}
            name="groups"
          />
        ) : (
          <LoadingSkeleton />
        )}
      </form>
    </FormProvider>
  );
};

export default GroupPanel;
