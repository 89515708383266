import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

import { authRoutes, combinedRoutes } from 'router/router';

import { setCredentials, setRefreshToken } from 'store/authSlice';

import { useRefreshTokenMutation } from 'api/usersSlice';

import { useAppDispatch, useAppSelector } from 'util/hooks/hooks';

import Navbar from 'components/common/Navbar';
import Toast from 'components/common/Toast';
import LoginForm from 'views/Auth/LoginForm';

const App = () => {
  const savedUser = localStorage.getItem('user');
  const sessionUser = sessionStorage.getItem('user');
  const { user } = useAppSelector(state => state.authUser);
  const dispatch = useAppDispatch();
  const { access_token } = useAppSelector(state => state.authUser);
  const timeoutInMinutes = process.env.REACT_APP_TOKEN_TIMEOUT ?? '60';

  const timeoutInMs = parseInt(timeoutInMinutes) * 60000;

  const [refreshToken, status] = useRefreshTokenMutation();

  useEffect(() => {
    if (savedUser) {
      dispatch(setCredentials(JSON.parse(savedUser)));
    } else if (sessionUser) {
      dispatch(setCredentials(JSON.parse(sessionUser)));
    }

    if (access_token) {
      const interval = setInterval(() => {
        refreshToken();
        if (status.data?.access_token) {
          dispatch(setRefreshToken(status.data?.access_token));
        }
      }, timeoutInMs);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!!user && <Navbar />}
      <div className="min-h-screen bg-gray-100">
        <Routes>
          {!user &&
            authRoutes.map(({ path, component: Element }, idx) => (
              <Route
                key={`route-auth-${idx}`}
                path={path}
                element={<Element />}
              />
            ))}
          {!!user &&
            combinedRoutes.map(({ path, component: Element }, idx) => (
              <Route key={`route-${idx}`} path={path} element={<Element />} />
            ))}
          <Route
            key="route-wildcard"
            path="*"
            element={!user ? <LoginForm /> : <Navigate to="/dashboard" />}
          />
        </Routes>
      </div>
      <Toast />
    </>
  );
};

export default App;
