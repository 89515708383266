import clsx from 'clsx';
import { ClassNamesProp, ReactComponent } from 'typings/common/react';

interface Props extends ClassNamesProp {
  withPadding?: boolean;
  defaultBg?: boolean;
  tag?: string;
}

export const Container: ReactComponent<Props> = ({
  children,
  className = '',
  defaultBg = true,
  withPadding = true,
  tag = 'div',
}) => {
  const classes = clsx({
    'px-0': !withPadding,
    'bg-gray-100': defaultBg,
    [className]: !!className,
    'container mx-auto': true,
  });

  const Tag = tag as keyof JSX.IntrinsicElements;
  return <Tag className={classes}>{children}</Tag>;
};
