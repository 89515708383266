import { AddCarrierParams, Brand, InvertedBrand } from 'typings/api/brands';
import {
  BasicResponse,
  DefaultQueryParams,
  GenericResponse,
} from 'typings/api/common';

import { apiSlice } from './apiSlice';

const brandsSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getBrands: builder.query<GenericResponse<Brand[]>, DefaultQueryParams>({
      query: ({ id, search, page }) => ({
        url: `/brands`,
        method: 'GET',
        params: { search, page: search ? 1 : page, installation: id },
      }),
      providesTags: ['Brand'],
    }),
    updateBrands: builder.mutation<BasicResponse, InvertedBrand[]>({
      query: payload => ({
        url: `/brands`,
        method: 'POST',
        body: { brands: payload },
      }),
    }),
    deleteBrand: builder.mutation<BasicResponse, number>({
      query: (id: number) => ({
        url: `/brands/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Brand'],
    }),
    runACheck: builder.mutation<BasicResponse, void>({
      query: () => ({
        url: '/commands/run/checkBrands',
        method: 'POST',
      }),
    }),
    removeAllCarriersFromBrands: builder.mutation<BasicResponse, number>({
      query: (id: number) => ({
        url: `/installations/${id}/remove-carriers`,
        method: 'GET',
      }),
      invalidatesTags: ['Brand'],
    }),
    addCarrierToAllFields: builder.mutation<BasicResponse, AddCarrierParams>({
      query: ({ carrier_id, installation_id }) => ({
        url: `/installations/add-carrier`,
        method: 'POST',
        body: { carrier_id, installation_id },
      }),
      invalidatesTags: ['Brand'],
    }),
  }),
});

export const {
  useGetBrandsQuery,
  useUpdateBrandsMutation,
  useDeleteBrandMutation,
  useRunACheckMutation,
  useRemoveAllCarriersFromBrandsMutation,
  useAddCarrierToAllFieldsMutation,
} = brandsSlice;
