import { useFormContext } from 'react-hook-form';
import { Dictionary, ReactComponent } from 'typings/common/react';
import FormField from 'components/form/FormField';

interface Props {
  description: string;
  items: Dictionary<string>[];
}

const ConfigNestedFieldsBlock: ReactComponent<Props> = ({
  description,
  items,
}) => {
  const { register } = useFormContext();

  return (
    <div className="flex flex-col gap-6 w-full">
      <p className="text-md">{description}</p>
      <div className="grid md:grid-cols-4 grid-cols-2  gap-2">
        {items?.map((item, idx) => {
          const [objectItems] = Object.entries(item);
          return (
            <FormField
              type="text"
              register={register}
              label={item.label}
              name={`nested.${objectItems[0]}`}
              key={`nested-${description}-${idx}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ConfigNestedFieldsBlock;
