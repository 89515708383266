import { ReactComponent as ExclamationPoint } from 'assets/svg/exclamation-point.svg';
import { useTranslation } from 'react-i18next';

const InactiveBadge = () => {
  const { t } = useTranslation();
  return (
    <div className="group items-center flex flex-col">
      <ExclamationPoint />
      <div className="hidden group-hover:block">
        <p className="transition transform ease-in-out duration-300 bg-white drop-shadow-lg absolute -left-4 px-4 py-2 rounded-md z-20">
          {t('global.row_need_configuring')}
        </p>
      </div>
    </div>
  );
};

export default InactiveBadge;
