import {
  FieldValues,
  FormProvider,
  useForm,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTable, usePagination } from 'react-table';

import {
  useAddCarrierToAllFieldsMutation,
  useDeleteBrandMutation,
  useGetBrandsQuery,
  useRemoveAllCarriersFromBrandsMutation,
  useRunACheckMutation,
  useUpdateBrandsMutation,
} from 'api/brandsSlice';
import { useGetSelectOptionsQuery } from 'api/apiSlice';
import { useGetConfigurationsQuery } from 'api/configurationSlice';

import useRenderTableCell from 'util/hooks/useRenderTableCell';
import { findErrorsfromBrandsPayload } from 'util/errorHelpers';
import {
  parseBrandsToTableData,
  parseBrandsDataToApiData,
} from 'util/parsingHelpers';

import { ReactComponent } from 'typings/common/react';
import { Brand } from 'typings/api/brands';
import { ResponseMeta } from 'typings/api/common';
import { Installation } from 'typings/entity/installations';

import { brandsColumns } from 'constants/brandsColumns';

import LoadingSkeleton from 'components/common/LoadingSkeleton';
import Table from 'components/Table/Table';
import TableButtons from 'components/Table/TableButtons';
import Input from 'components/form/Input';
import Button from 'components/common/Button';
import SimpleSelect from 'components/form/SimpleSelect';

interface Props {
  installation: Installation;
}

const defaultValues: {
  search: string;
  brands: Brand[];
  meta?: ResponseMeta;
} = {
  search: '',
  brands: [],
};

const BrandPanel: ReactComponent<Props> = ({ installation }) => {
  const { id, name } = installation;
  const [deleteTrigger] = useDeleteBrandMutation();
  const [updateBrandsTrigger, updateStatus] = useUpdateBrandsMutation();
  const [checkBrandsTrigger, checkStatus] = useRunACheckMutation();
  const [
    removeInactiveCarriersFromBrands,
    removeInactiveCarriersFromBrandsStatus,
  ] = useRemoveAllCarriersFromBrandsMutation();
  const [addCarrierToBrands, addCarrierToBrandsStatus] =
    useAddCarrierToAllFieldsMutation();

  const { t } = useTranslation();
  const { data: carriersData, isLoading: carriersLoading } =
    useGetSelectOptionsQuery({
      installationId: id,
      endpoint: '/carriers',
    });

  const { isLoading: configsLoading } = useGetConfigurationsQuery({
    id,
    page: 1,
    search: '',
  });

  const methods = useForm<typeof defaultValues>({
    defaultValues,
  });

  const carrierFields = useForm<FieldValues>({
    defaultValues: { carrierToAdd: null },
  });

  const { carrierToAdd } = useWatch({ control: carrierFields.control });

  const { control, register, reset, handleSubmit, setError } = methods;

  const formValues = useWatch({ control });
  const { dirtyFields } = useFormState({ control });

  const renderTableCell = useRenderTableCell({
    register,
    name: 'brands',
    installation,
  });
  const tableProps = useTable(
    {
      columns: brandsColumns as any,
      data: formValues.brands || [],
      defaultColumn: { Cell: renderTableCell },
      pageCount: formValues.meta?.last_page || 1,
      manualPagination: true,
    },
    usePagination
  );

  const { data, isLoading, isFetching, refetch } = useGetBrandsQuery({
    id,
    search: formValues.search || '',
    page: tableProps.state.pageIndex + 1,
  });

  useEffect(() => {
    reset({
      search: formValues.search,
      brands:
        isFetching || isLoading || carriersLoading
          ? []
          : parseBrandsToTableData(data?.data || [], carriersData || []),
      meta: data?.meta,
    });
  }, [
    carriersLoading,
    formValues.search,
    data?.meta,
    isFetching,
    isLoading,
    reset,
    data?.data,
    carriersData,
  ]);

  const onSubmit = (formData: FieldValues) => {
    const fieldsToParse = dirtyFields.brands
      ?.map((_, idx) => idx)
      .filter(item => typeof item === 'number');

    const payload = parseBrandsDataToApiData(
      formData.brands,
      fieldsToParse || []
    );

    updateBrandsTrigger(payload || [])
      .unwrap()
      .then(() => refetch())
      .catch(err => {
        const appErrors = findErrorsfromBrandsPayload(
          err.data.errors,
          payload || []
        );
        appErrors.forEach(item => {
          if (item) {
            const [keyValuePair] = Object.entries(item);
            setError(keyValuePair[0] as keyof typeof formValues, {
              message: keyValuePair[1],
            });
          }
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <Input
        className="w-full h-10 rounded-md mt-6 shadow-sm pl-2 placeholder:text-md md:mt-12"
        placeholder={t('global.search')!}
        register={register}
        name="search"
        type="search"
      />
      <div className="flex flex-col py-2 gap-2 lg:justify-start justify-between mt-2">
        <Button
          onClick={() => removeInactiveCarriersFromBrands(id)}
          disabled={removeInactiveCarriersFromBrandsStatus.isLoading}
        >
          {t('brands.removeInactiveCarriers')}
        </Button>
        <div className="flex items-end gap-4">
          <SimpleSelect
            {...carrierFields}
            data={carriersData || []}
            name="carrierToAdd"
            label={t('brands.carrier')}
          />
          <Button
            disabled={!carrierToAdd || addCarrierToBrandsStatus.isLoading}
            onClick={() =>
              addCarrierToBrands({
                carrier_id: carrierToAdd.value,
                installation_id: id,
              })
            }
          >
            {t('brands.addCarrierToAllBrands', { store: name })}
          </Button>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TableButtons
          disabled={checkStatus.isLoading || updateStatus.isLoading}
          checkAction={checkBrandsTrigger}
          checkActionName="actions.brands.runACheck"
        />
        {!isLoading &&
        tableProps.state.pageIndex >= 0 &&
        !isFetching &&
        !configsLoading ? (
          <Table
            meta={data?.meta}
            deleteFn={deleteTrigger}
            tableProps={tableProps}
            name="brands"
          />
        ) : (
          <LoadingSkeleton />
        )}
      </form>
    </FormProvider>
  );
};

export default BrandPanel;
