export const clientsTableColumns = [
  {
    accessor: 'client_id',
    id: 'inactiveIcon',
    header: '',
    type: 'inactiveIcon',
  },
  {
    accessor: 'company',
    header: 'clients.company',
    type: 'plain',
  },
  {
    accessor: 'client_id',
    header: 'clients.account_id',
    type: 'select',
    searchUrl: '/clients',
    isAsync: true
  },
  {
    accessor: 'prices',
    header: 'clients.has_prices',
    type: 'plain',
  },
  {
    accessor: 'email',
    header: 'clients.email',
    type: 'plain',
  },
  {
    accessor: 'additional',
    header: 'clients.additional',
    type: 'plain',
  }
];