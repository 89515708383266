import { ReactComponent } from 'typings/common/react';

const LoadingSkeleton: ReactComponent = () => {
  return (
    <div className="grid place-items-center h-screen">
      <div role="status" className="w-full animate-pulse">
        {Array(10)
          .fill(0)
          .map((_, idx) => (
            <div
              className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-6"
              key={`bar-${idx}`}
            />
          ))}
      </div>
    </div>
  );
};

export default LoadingSkeleton;
