import { DashboardResponse } from 'typings/api/dashboard';
import { apiSlice } from './apiSlice';

const dashboardSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDashboardItems: builder.query<DashboardResponse, void>({
      query: () => ({
        url: `/dashboard`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetDashboardItemsQuery } = dashboardSlice;
