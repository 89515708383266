import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Combobox, Transition } from '@headlessui/react';
import {
  Control,
  FieldError,
  FieldValues,
  useController,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import clsx from 'clsx';

import { ReactComponent as RC } from 'typings/common/react';
import { SelectOption } from 'typings/form/form';

import { filterQueryResults } from 'util/tableHelpers';

import FormLabel from './FormLabel';
import { ReactComponent as ChevornDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as CrossIcon } from 'assets/svg/x-lg.svg';
import Button from 'components/common/Button';

interface Props {
  name: string;
  data: SelectOption[];
  label?: string | null;
  required?: boolean;
  disabled?: boolean;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  error?: FieldError;
}

const SimpleSelect: RC<Props> = ({
  name,
  label,
  required,
  data,
  control,
  setValue,
  getValues,
  error,
  disabled = false,
}) => {
  const [query, setQuery] = useState('');
  const { t } = useTranslation();

  const fieldDefaultValue = data?.find(
    ({ value }) => value === getValues(name)
  );

  const {
    field: { value, onChange },
  } = useController({ name, control, defaultValue: null });

  const filteredResults = !query ? data : filterQueryResults(data, query);

  const selectButtonClasses = clsx({
    'text-purple-light': !disabled,
    'text-purple-disabled': disabled,
  });

  const inputFieldClasses = clsx(
    'w-full py-2 pl-3 pr-10 text-sm leading-5 focus:ring-0 h-8 rounded',
    {
      'border-2 border-danger-light': error,
      'border-purple-light bg-white text-gray-900': !disabled && !error,
      'border-purple-disabled bg-gray-100 text-gray-200': disabled,
    }
  );
  const clearValueButtonClasses = clsx({
    'text-danger-light': !disabled,
    'text-danger-disabled': disabled,
  });

  return (
    <>
      <div className="w-56 justify-end">
        <Combobox
          as="div"
          value={fieldDefaultValue || value}
          onChange={onChange}
          disabled={disabled}
        >
          <>
            {label && (
              <FormLabel inputId={name} required={required}>
                {label}
              </FormLabel>
            )}
            <span className="rounded-md shadow-sm relative">
              <Combobox.Input
                onChange={event => setQuery(event.target.value)}
                displayValue={(value: SelectOption) => value?.label ?? ''}
                className={inputFieldClasses}
                placeholder={t('global.search')}
                id={name}
              />
              <div className="absolute inset-y-0 right-0 flex items-center gap-2 pr-2">
                <Button
                  className={clearValueButtonClasses}
                  onClick={() => setValue(name, null, { shouldDirty: true })}
                  disabled={disabled}
                >
                  <CrossIcon />
                </Button>
                <Combobox.Button className={selectButtonClasses}>
                  <ChevornDown />
                </Combobox.Button>
              </div>
            </span>

            <Transition
              leave="transition ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="relative  rounded-md bg-white shadow-lg z-50"
            >
              <Combobox.Options className="max-h-60 rounded py-1 shadow-md overflow-x-auto focus:outline-none text-sm leading-5 w-56 bg-white absolute">
                {(filteredResults?.length === 0 && query !== '') ||
                filteredResults?.length === 0 ? (
                  <div className="cursor-default select-none py-2 px-4 text-center ">
                    {t('global.nothing_found')}
                  </div>
                ) : (
                  filteredResults?.map(item => (
                    <Combobox.Option
                      key={item.value}
                      className="cursor-pointer select-none py-2 px-4 overflow-x hover:bg-pink"
                      value={item}
                      as="p"
                    >
                      {item.label}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </>
        </Combobox>
        {error?.message && (
          <p className="text-sm text-danger-light">{error.message}</p>
        )}
      </div>
    </>
  );
};

export default SimpleSelect;
