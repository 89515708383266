import clsx from 'clsx';

import { ReactComponent } from 'typings/common/react';

interface Props {
  required?: boolean;
  inputId: string;
}

const FormLabel: ReactComponent<Props> = ({
  required = false,
  children,
  inputId,
}) => {
  const classes = clsx('text-sm inline-flex pb-1', {
    "after:content-['*'] after:text-danger-light": required,
  });
  return (
    <label htmlFor={inputId} className={classes}>
      {children}
    </label>
  );
};

export default FormLabel;
