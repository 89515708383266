import { FieldValues } from 'react-hook-form';
import { ConfigurationGroup } from 'typings/api/configurations';
import { Installation } from 'typings/entity/installations';

export const groupsTableColumns = [
  {
    accessor: 'is_active',
    id: 'inactiveIcon',
    header: '',
    type: 'inactiveIcon',
  },
  {
    accessor: 'group.axapta_group',
    header: 'groups.axapta_group',
    type: 'plain',
  },
  {
    accessor: 'group.name',
    header: 'groups.name',
    type: 'text',
  },
  {
    accessor: 'category_id',
    header: 'groups.category_id',
    type: 'select',
    searchUrl: '/categories',
  },

  {
    accessor: 'group.is_alcohol',
    header: 'groups.is_alcohol',
    type: 'checkbox',
  },
  {
    accessor: 'has_box',
    header: 'groups.has_box',
    isDisabled: (formValues: FieldValues, installation?: Installation) =>
      !installation?.hasBoxProducts,
    type: 'checkbox',
  },
  {
    accessor: 'box_discount',
    header: 'groups.box_discount',
    type: 'number',
    isDisabled: (formValues: FieldValues, installation?: Installation) =>
      !formValues?.has_box || !installation?.hasBoxProducts,
    setValueFromConfigurations: (configValues: ConfigurationGroup[]) =>
      configValues?.find(
        item => item.configuration.code === 'default_box_discount'
      )?.value,
  },
  {
    accessor: 'group.is_configurable',
    header: 'groups.is_configurable',
    type: 'checkbox',
  },
  {
    accessor: 'config.0',
    header: 'groups.config_1',
    type: 'select',
    searchUrl: '/attribute-groups',
    isDisabled: (formValues: FieldValues) => !formValues?.group.is_configurable,
  },
  {
    accessor: 'config.1',
    header: 'groups.config_2',
    type: 'select',
    searchUrl: '/attribute-groups',
    isDisabled: (formValues: FieldValues) => !formValues?.group.is_configurable,
  },
];

export const dashBoardGroupsColumns = [
  {
    accessor: 'group.axapta_group',
    header: 'groups.axapta_group',
    type: 'plain',
  },
  {
    accessor: 'created_at',
    header: 'created_at',
    type: 'date',
  },
];
