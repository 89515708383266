import { useTranslation } from 'react-i18next';
import { Cell, TableInstance } from 'react-table';

import { ResponseMeta } from 'typings/api/common';
import { ReactComponent } from 'typings/common/react';

import Pagination from './Pagination';
import StatusBadge from 'components/Table/StatusBadge';

interface Props {
  tableProps: TableInstance<any>;
  meta?: ResponseMeta;
}

const SimpleTable: ReactComponent<Props> = ({ tableProps, meta }) => {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    state: { pageIndex },
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
  } = tableProps!;

  const paginationProps = {
    pageIndex,
    nextPage,
    previousPage,
    gotoPage,
    canNextPage,
    canPreviousPage,
    pageCount: meta?.last_page || 1,
  };

  const renderCell = (cell: Cell) => {
    if (cell.column.type === 'status') {
      return (
        <StatusBadge status={cell.value}>
          {t(`status.${cell.value}`)}
        </StatusBadge>
      );
    }
    if (cell.column.type === 'plain') {
      if (cell.value instanceof Array) {
        return cell.value.join(', ');
      } else if (cell.value instanceof Object) {
        return Object.keys(cell.value).map((key, idx) => (
          <p key={`item-${idx}`}>{`${key}: ${cell.value[key]}`}</p>
        ));
      }
      return cell.value;
    } else if (cell.column.type === 'date') {
      const date = new Date(cell.value);
      return date.toLocaleDateString();
    }
  };

  return (
    <div className="overflow-x-scroll">
      <table
        {...getTableProps()}
        className="w-full text-sm text-left text-gray-500 table-auto border-spacing-y-2 border-separate "
      >
        <thead className="text-gray-200 uppercase bg-gray-light text-sm">
          {headerGroups.map((headerGroup, rIdx) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`row-${rIdx}`}>
              {headerGroup.headers.map((column, cIdx) => (
                <th
                  {...column.getHeaderProps()}
                  className="py-3 px-6 text-gray-200"
                  key={`row-${rIdx}-column-${cIdx}`}
                >
                  {t(column.render('header') as string)}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(meta ? page : rows)?.map((row, rIdx) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="border-spacing-y-4 border-separate text-gray-300"
                key={`row-${rIdx}`}
              >
                {row.cells?.map((cell, cIdx) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="bg-white px-6 first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg py-2"
                      key={`row-${rIdx}-cell-${cIdx}`}
                    >
                      {renderCell(cell)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {meta && <Pagination {...paginationProps} meta={meta} />}
    </div>
  );
};

export default SimpleTable;
