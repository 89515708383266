import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { combinedRoutes } from 'router/router';

import { ReactComponent } from 'typings/common/react';

import { logOut } from 'store/authSlice';

import { useAppDispatch } from 'util/hooks/hooks';

import Button from './Button';
import { Container } from './Container';

const Navbar: ReactComponent = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Container
      className="flex py-6 gap-2 flex-col items-center bg-white md:flex-row md:h-20 "
      defaultBg={false}
      tag="nav"
    >
      {combinedRoutes.map(({ navBarLink, name, path }, idx) => {
        const linkClasses = clsx('text-md mr-2', {
          'text-purple-dark underline': pathname === path,
          'text-gray-200 hover:text-purple-dark duration-300':
            pathname !== path,
        });
        return (
          navBarLink && (
            <Link to={path} className={linkClasses} key={`nav-link-${idx}`}>
              {t(name ?? '')}
            </Link>
          )
        );
      })}
      <Button
        className="text-md text-gray-200 hover:text-purple-dark duration-300 md:ml-auto"
        onClick={() => {
          dispatch(logOut());
          navigate('/login');
        }}
      >
        {t('user.logout')}
      </Button>
    </Container>
  );
};

export default Navbar;
