export const logsTableColumns = [
  {
    accessor: 'data_id',
    header: 'logs.data_id',
    type: 'plain',
  },
  {
    accessor: 'product_name',
    header: 'logs.product_name',
    type: 'plain',
  },
  {
    accessor: 'attribute_data',
    header: 'logs.attribute_data',
    type: 'plain',
  },
  {
    accessor: 'attribute_group',
    header: 'logs.attribute_group',
    type: 'plain',
  },
  {
    accessor: 'failed_attribute',
    header: 'logs.failed_attribute',
    type: 'plain',
  },
];
