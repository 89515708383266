import React from 'react';
import { Transition } from '@headlessui/react';
import { Toaster, resolveValue } from 'react-hot-toast';
import clsx from 'clsx';

import { ReactComponent } from 'typings/common/react';

const Toast: ReactComponent = () => {
  return (
    <Toaster position="top-right">
      {toastObj => {
        const classes = clsx(
          'transform inline-flex items-center py-4 px-8 gap-2 text-white rounded shadow-lg',
          {
            'bg-success': toastObj.type === 'success',
            'bg-danger-light': toastObj.type === 'error',
            'bg-warning': toastObj.type === 'loading',
          }
        );

        return (
          <Transition
            appear
            show={toastObj.visible}
            className={classes}
            enter="transition-all duration-150"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
          >
            <p className="text-md">
              {resolveValue(toastObj.message, toastObj)}
            </p>
          </Transition>
        );
      }}
    </Toaster>
  );
};

export default Toast;
