export const brandsColumns = [
  {
    accessor: 'is_active',
    id: 'inactiveIcon',
    header: '',
    type: 'inactiveIcon',
  },
  {
    accessor: 'brand.name',
    header: 'brands.brand_name',
    type: 'plain',
  },
  {
    accessor: 'ecommerce_brand_id',
    header: 'brands.brand_id',
    type: 'select',
    searchUrl: '/brands',
  },
  {
    accessor: 'ecommerce_brand_shop_id',
    header: 'brands.brand_shop',
    type: 'select',
    searchUrl: '/shops',
    blacklistedValue: 'default_shop_id',
  },
  {
    accessor: 'carriers',
    header: 'brands.carriers',
    type: 'multiselect',
    searchUrl: '/carriers',
  },
];

export const dashBoardBrandsColumns = [
  {
    accessor: 'brand.name',
    header: 'brands.brand_name',
    type: 'plain',
  },
  {
    accessor: 'created_at',
    header: 'created_at',
    type: 'date',
  },
];
