import { Dialog } from '@headlessui/react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as RC } from 'typings/common/react';

import Button from 'components/common/Button';

import { ReactComponent as CheckmarkIcon } from 'assets/svg/check-lg.svg';
import { ReactComponent as CancelIcon } from 'assets/svg/x-lg.svg';

interface Props {
  isOpen: { state: boolean; id: number };
  setOpen: Dispatch<SetStateAction<{ state: boolean; id: number }>>;
  title: string;
  onConfirm: (param: number) => void;
}

const ConfirmationModal: RC<Props> = ({
  isOpen,
  setOpen,
  title,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen.state}
      onClose={() => setOpen({ ...isOpen, state: false })}
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full justify-center p-4 items-center">
          <Dialog.Panel className=" transform overflow-hidden rounded-lg bg-gray-100 p-8 text-left shadow-xl transition-all">
            <Dialog.Title
              as="h1"
              className="text-h1 text-gray-300 text-center pb-2"
            >
              {t(title)}
            </Dialog.Title>
            <div className="bg-gray-100 pt-6 justify-around flex">
              <Button
                onClick={() => {
                  onConfirm(isOpen.id);
                  setOpen({ ...isOpen, state: false });
                }}
                className="bg-success text-white rounded py-4 px-6 inline-flex items-center"
              >
                <CheckmarkIcon className="text-white h-6 w-6" />
              </Button>
              <Button
                onClick={() => {
                  setOpen({ ...isOpen, state: false });
                }}
                className="bg-danger-light hover:bg-danger-dark duration-300 text-white rounded px-6"
              >
                <CancelIcon />
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
