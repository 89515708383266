import { Disclosure } from '@headlessui/react';

import { ReactComponent } from 'typings/common/react';

import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';

interface Props {
  buttonText: string;
  openByDefault?: boolean;
}

const ConfigDisclosure: ReactComponent<Props> = ({
  buttonText,
  children,
  openByDefault = false,
}) => (
  <Disclosure defaultOpen={openByDefault}>
    {({ open }) => (
      <>
        <Disclosure.Button className="py-2 px-8 bg-white rounded-md inline-flex items-center justify-between">
          <p className="text-md text-gray-200">{buttonText}</p>
          <ChevronRight className={`${open && 'rotate-90 transform'}`} />
        </Disclosure.Button>
        <Disclosure.Panel className="flex flex-wrap gap-x-7 gap-y-4 p-8 bg-white rounded-md items-end">
          {children}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

export default ConfigDisclosure;
