import { createApi } from '@reduxjs/toolkit/query/react';

import { SelectQueryparams } from 'typings/api/common';

import { SelectOption } from 'typings/form/form';

import baseQueryWithRefreshing from 'util/api';

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery: baseQueryWithRefreshing,
  tagTypes: [
    'Group',
    'Category',
    'Attribute',
    'Brand',
    'Log',
    'Job',
    'Configuration',
    'Client',
  ],
  refetchOnMountOrArgChange: 2,
  endpoints: builder => ({
    getSelectOptions: builder.query<SelectOption[], SelectQueryparams>({
      query: ({ installationId, endpoint }) => ({
        url: `/installations/${installationId}${endpoint}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetSelectOptionsQuery } = apiSlice;
