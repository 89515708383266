import clsx from 'clsx';
import { FieldError, UseFormRegister } from 'react-hook-form';

import { ClassNamesProp, ReactComponent } from 'typings/common/react';

import FormLabel from './FormLabel';

interface Props extends ClassNamesProp {
  name: string;
  type: 'input' | 'email' | 'text' | 'tel' | 'number' | 'password' | 'search';
  label?: string | null;
  register: UseFormRegister<any>;
  required?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  error?: FieldError;
}

const Input: ReactComponent<Props> = ({
  name,
  label,
  register,
  type,
  required,
  className,
  error,
  isDisabled = false,
  placeholder = '',
}) => {
  const classes = clsx(
    className ||
      'border-1 rounded h-8 px-2 bg-gray-100 text-sm transition-colors duration-300',
    {
      'bg-gray-100 text-gray-200 border-purple-disabled': isDisabled,
      'bg-white border-purple-light': !isDisabled && !error,
      'w-full': type === 'search',
      'border-2 border-danger-light rounded bg-white': error,
    }
  );
  const containerClasses = clsx('flex flex-col', {
    'max-w-sm': type !== 'number' && type !== 'search',
    'max-w-[7rem]': type === 'number',
  });
  return (
    <div className={containerClasses}>
      <FormLabel inputId={name} required={required}>
        {label}
      </FormLabel>
      <input
        className={classes}
        id={name}
        type={type}
        disabled={isDisabled}
        placeholder={placeholder}
        {...register(name, { valueAsNumber: type === 'number' })}
      />
      {error?.message && (
        <p className="text-sm text-danger-light">{error.message}</p>
      )}
    </div>
  );
};

export default Input;
