import { Fragment } from 'react';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';

import { Dictionary, ReactComponent } from 'typings/common/react';
import { Installation } from 'typings/entity/installations';
import { DashboardItem } from 'typings/api/dashboard';
import { DashbaordWidgetTypes } from 'typings/entity/dashboard';

import Button from './Button';
import { ReactComponent as ExclamationPoint } from 'assets/svg/exclamation-point.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/svg/check-lg.svg';
import { useTranslation } from 'react-i18next';

const variants = {
  button: {
    default: 'py-2 md:px-16 lg:w-auto',
    small: 'bg-white p-2 inline-flex items-center gap-2 justify-center',
  },
};

interface Props {
  data?: Installation[];
  variant?: 'small' | 'default';
  dashboardData?: Dictionary<DashboardItem<DashbaordWidgetTypes>>;
}

const TabHeader: ReactComponent<Props> = ({
  data,
  dashboardData,
  variant = 'default',
}) => {
  const { t } = useTranslation();
  return (
    <Tab.List className="flex lg:pb-0">
      {data?.map(({ name }, idx) => (
        <Tab as={Fragment} key={`tab-${idx}`}>
          {({ selected }) => {
            const classes = clsx(
              'border-b-2  w-full',
              variants.button[variant],
              {
                'bg-marine border-marine text-white':
                  name === 'BusinessAbestore' && selected,
                'text-marine border-marine':
                  name === 'BusinessAbestore' && !selected,

                'text-gray-300 border-gray-300':
                  name === 'WineFriend' && !selected,
                'bg-gray-300 border-gray-300':
                  name === 'WineFriend' && selected,

                'text-brandblue border-brandblue':
                    name === 'BusinessOralB' && !selected,
                'bg-brandblue border-brandblue':
                    name === 'BusinessOralB' && selected,

                'text-purple-light border-purple-light':
                  name === 'Abestore' && !selected,
                'bg-purple-light border-purple-light':
                  name === 'Abestore' && selected,
                'rounded-tl rounded-tr text-white': selected,
              }
            );
            return (
              <Button className={classes}>
                {t(`installations.${name}`)}
                {dashboardData && dashboardData[idx + 1].count > 0 && (
                  <ExclamationPoint />
                )}
                {dashboardData && dashboardData[idx + 1].count === 0 && (
                  <CheckmarkIcon className="text-success h-7 w-7" />
                )}
              </Button>
            );
          }}
        </Tab>
      ))}
    </Tab.List>
  );
};

export default TabHeader;
