import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm, useWatch } from 'react-hook-form';
import { usePagination, useTable } from 'react-table';

import {
  useGetJobsQueueQuery,
  useGetRunnableJobsQuery,
  useRunCommandMutation,
} from 'api/jobRunnerSlice';

import { ErrorResponse } from 'typings/api/common';
import { SelectOption } from 'typings/form/form';

import { parseJobRunnerResponseToSelectList } from 'util/parsingHelpers';

import { jobRunnerColumns } from 'constants/jobRunnerColumns';

import { Container } from 'components/common/Container';
import LoadingSkeleton from 'components/common/LoadingSkeleton';
import SimpleSelect from 'components/form/SimpleSelect';
import Button from 'components/common/Button';
import ErrorBlock from 'components/common/ErrorBlock';
import SimpleTable from 'components/Table/SimpleTable';

const JobRunner = () => {
  const { t } = useTranslation();

  const methods = useForm<FieldValues>({
    defaultValues: { search: '', job: null },
  });

  const { control, setValue, getValues, handleSubmit, reset, resetField } =
    methods;
  const formValues = useWatch({ control });

  const tableProps = useTable(
    {
      columns: jobRunnerColumns as any,
      data: formValues?.allJobs || [],
      pageCount: formValues.meta?.last_page || 1,
      manualPagination: true,
    },
    usePagination
  );
  const { data, isLoading, isFetching } = useGetJobsQueueQuery({
    search: formValues.search || '',
    page: tableProps.state.pageIndex + 1,
  });

  const { data: runnableJobsData } = useGetRunnableJobsQuery();

  const [runCommandTrigger, runCommandStatus] = useRunCommandMutation();

  const runJobButtonDisabled = !formValues.job || runCommandStatus.isLoading;

  const onSubmit = async (data: FieldValues) => {
    const command = data.job.label;
    delete data.search;
    delete data.jobs;
    delete data.job;

    const payload = Object.keys(data).reduce((acc, key) => {
      const item = data[key];

      return {
        ...acc,
        ...(item && { [key]: !!item?.value ? item.value : item.label }),
      };
    }, {});
    await runCommandTrigger({ command, data: payload });
  };

  useEffect(() => {
    reset({
      search: formValues.search,
      meta: data?.meta,
      allJobs: data?.data,
    });
  }, [data?.data, data?.meta, formValues.search, reset]);

  useEffect(() => {
    Object.keys(formValues).forEach(
      key =>
        !['search', 'meta', 'allJobs', 'job'].includes(key) && resetField(key)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.job?.label, resetField]);

  return (
    <Container>
      {runnableJobsData && (
        <form
          className="flex gap-2 pt-8 items-end"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SimpleSelect
            name="job"
            label={t('jobRunner.action')}
            data={parseJobRunnerResponseToSelectList(runnableJobsData)?.map(
              ({ label, value }) => ({ label, value })
            )}
            control={control}
            getValues={getValues}
            setValue={setValue}
          />

          {formValues.job &&
            parseJobRunnerResponseToSelectList(runnableJobsData)
              .find(
                item => item.label === (formValues.job as SelectOption)?.label
              )
              ?.relatedFields.map(field => (
                <SimpleSelect
                  name={field.name}
                  data={field.fields}
                  label={t(`jobRunner.${field.name}`)}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                />
              ))}
          <Button
            disabled={runJobButtonDisabled}
            type="submit"
            variant="skinny"
          >
            {t('jobRunner.actions.runJob')}
          </Button>
        </form>
      )}

      {!isLoading && tableProps.state.pageIndex >= 0 && !isFetching ? (
        <SimpleTable tableProps={tableProps} meta={data?.meta} />
      ) : (
        <LoadingSkeleton />
      )}
      <ErrorBlock
        type="toast"
        errors={runCommandStatus?.error as ErrorResponse}
      />
    </Container>
  );
};

export default JobRunner;
