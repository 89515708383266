import {
  DefaultQueryParams,
  BasicResponse,
  GenericResponse,
} from 'typings/api/common';
import { Client, ClientUpdateBody } from 'typings/api/clients';

import { apiSlice } from './apiSlice';

const clientsSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getClients: builder.query<GenericResponse<Client[]>, DefaultQueryParams>({
      query: ({ id, search, page }) => ({
        url: `/clients/${id}`,
        method: 'GET',
        params: { search, page },
      }),
      providesTags: ['Client'],
    }),
    updateClient: builder.mutation<BasicResponse, ClientUpdateBody>({
      query: ({ payload, id }) => ({
        url: `/clients/${id}`,
        method: 'POST',
        body: { clients: payload },
      }),
    }),
  }),
});

export const { useGetClientsQuery, useUpdateClientMutation } = clientsSlice;
