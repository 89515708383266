import { useEffect } from 'react';
import { FormProvider, useForm, useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePagination, useTable } from 'react-table';

import { Client, ClientsRow } from 'typings/api/clients';
import { ResponseMeta } from 'typings/api/common';
import { ReactComponent } from 'typings/common/react';

import { clientsTableColumns } from 'constants/clientsColumns';

import { useGetClientsQuery, useUpdateClientMutation } from 'api/clientsSlice';

import { findErrorsFromClientsPayload } from 'util/errorHelpers';
import useRenderTableCell from 'util/hooks/useRenderTableCell';

import LoadingSkeleton from 'components/common/LoadingSkeleton';
import Table from 'components/Table/Table';
import TableButtons from 'components/Table/TableButtons';
import Input from 'components/form/Input';
import { Installation } from 'typings/entity/installations';

interface Props {
    installation: Installation;
}

const defaultValues: {
    search: string;
    clients: ClientsRow[];
    meta?: ResponseMeta;
} = {
    search: '',
    clients: [],
};
const ClientPanel: ReactComponent<Props> = ({ installation }) => {
    const { t } = useTranslation();

    const [updateClients, updateStatus] = useUpdateClientMutation();
    const methods = useForm<typeof defaultValues>({
        defaultValues,
    });

    const { control, register, reset, handleSubmit, setError } = methods;
    const formValues = useWatch({ control });

    const { dirtyFields } = useFormState({ control });

    const renderTableCell = useRenderTableCell({
        register,
        name: 'clients',
        installation,
    });

    const tableProps = useTable(
        {
            columns: clientsTableColumns as any,
            data: formValues.clients || [],
            defaultColumn: { Cell: renderTableCell },
            pageCount: formValues.meta?.last_page || 1,
            manualPagination: true,
        },
        usePagination
    );

    const { data, isLoading, isFetching, refetch } = useGetClientsQuery({
        id: installation.id,
        search: formValues.search || '',
        page: tableProps.state.pageIndex + 1,
    });

    const formatClientData = (data: Client[]) => {
        const result = data.map(
            ({
                 id,
                 client_id,
                 siret,
                 firstname,
                 lastname,
                 customer_alias,
                 company,
                 label,
                 email,
                 has_prices,
                 client_account_id,
             }) => {
                const siretValue = siret ? `RegNo: ${siret}` : '';
                const alias = customer_alias ? `; ${customer_alias}` : '';
                const companyValue = company ? `; Ettevõte: ${company}` : '';
                const name = firstname && lastname ? `; Nimi: ${firstname} ${lastname}` : '';
                const additional = `${siretValue}${alias} ${companyValue} ${name}`;
                const prices = has_prices ? "Jah" : "Ei";
                return {
                    id,
                    client_id,
                    company,
                    label,
                    email,
                    additional,
                    prices,
                    account_id: client_account_id?.toString() || '',
                    if_error: !client_id,
                };
            }
        );
        return result;
    };

    useEffect(() => {
        reset({
            search: formValues.search,
            clients:
                isLoading || isFetching ? [] : formatClientData(data?.data || []),
            meta: data?.meta,
        });
    }, [data, isLoading, isFetching, reset, formValues.search]);

    const saveChanges = (formData: typeof defaultValues) => {
        const payload = (dirtyFields.clients || [])
            .map((_, index) => {
                const { id, client_id } = formData.clients[index];
                return {
                    id,
                    index,
                    client_id: client_id,
                };
            })
            .filter(item => !!item);
        if (payload) {
            updateClients({
                payload,
                id: installation.id,
            })
                .unwrap()
                .then(() => refetch())
                .catch(err => {
                    const appErrors = findErrorsFromClientsPayload(
                        err.data.errors,
                        payload || []
                    );
                    appErrors.forEach(item => {
                        const [keyValuePair] = Object.entries(item!);
                        setError(keyValuePair[0] as keyof typeof formValues, {
                            message: keyValuePair[1],
                        });
                    });
                });
        }
    };

    return (
        <FormProvider {...methods}>
            <Input
                className="w-full h-10 rounded-md mt-6 shadow-sm pl-2 placeholder:text-md md:mt-12"
                placeholder={t('global.search')!}
                register={register}
                name="search"
                type="search"
            />
            {!isLoading && tableProps.state.pageIndex >= 0 && !isFetching ? (
                <form onSubmit={handleSubmit(saveChanges)} className="mt-4">
                    <TableButtons disabled={updateStatus.isLoading} />
                    <Table meta={data?.meta} name="clients" tableProps={tableProps} />
                </form>
            ) : (
                <LoadingSkeleton />
            )}
        </FormProvider>
    );
};

export default ClientPanel;
