import { FieldValues } from 'react-hook-form';
import { Installation } from 'typings/entity/installations';
import { SelectOption } from 'typings/form/form';

export const filterQueryResults = (data: SelectOption[], query: string) =>
  data?.filter(option => {
    const label = option?.label;
    if (typeof label === 'string') {
      return option.label?.toLowerCase().includes(query?.toLowerCase());
    } else if (typeof label === 'number') {
      return option.label === query;
    }
    return option;
  });

export const setDefaultTab = (data: Installation[], urlTab: number): number =>
  data.findIndex(install => install.id === urlTab) || 0;

export const compareValues = (
  value1: string | number,
  value2: string | number
) => {
  return value1.toLocaleString().localeCompare(value2.toLocaleString());
};

export const getDisabledValue = (
  isDisabled:
    | ((formValues: FieldValues, installation?: Installation) => boolean)
    | boolean
    | null,
  formValues: FieldValues,
  groupId: string,
  row: string,
  installation?: Installation
) => {
  return isDisabled
    ? typeof isDisabled === 'boolean'
      ? isDisabled
      : isDisabled(formValues[groupId][row], installation)
    : false;
};
