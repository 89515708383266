import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegister } from 'react-hook-form';

import { RenderTablecellCallbackParams } from 'typings/table/table';
import { Installation } from 'typings/entity/installations';

import FormField from 'components/form/FormField';
import InactiveBadge from 'components/Table/InactiveBadge';

interface Props {
  register: UseFormRegister<any>;
  name: string;
  installation?: Installation;
}

const useRenderTableCell = ({ register, name, installation }: Props) => {
  const { t } = useTranslation();

  return useCallback(
    ({ row, cell }: RenderTablecellCallbackParams) => {
      if (cell.column.type === 'inactiveIcon') {
        return !cell.value && <InactiveBadge />;
      }

      if (cell.column.type === 'boolean') {
        return cell.value ? t('global.yes') : t('global.no');
      }
      if (cell.column.type === 'plain') {
        if (cell.value instanceof Array) {
          return cell.value.join(',');
        } else if (cell.value instanceof Object) {
          return Object.keys(cell.value)
            .map((key, idx) => (
              <p key={`item-${idx}`}>{`${key}: ${cell.value[key]}`}</p>
            ))
            .join(',');
        }
        return cell.value;
      } else if (
        [
          'input',
          'number',
          'checkbox',
          'select',
          'text',
          'multiselect',
        ].includes(cell.column.type)
      ) {
        return (
          <FormField
            register={register}
            name={`${name}.${row.index}.${cell.column.id}`}
            type={cell.column.type}
            label=""
            isDisabled={cell.column?.isDisabled ?? null}
            isAsync={cell.column?.isAsync ?? false}
            installation={installation}
            searchUrl={cell.column?.searchUrl}
            setValueFromConfigurations={cell.column?.setValueFromConfigurations}
            blacklistedSelectValue={cell.column?.blacklistedValue}
            key={`${name}.${row.index}.${cell.column.id}`}
          />
        );
      }
    },
    [register, name, t, installation]
  );
};

export default useRenderTableCell;
