import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent } from 'typings/common/react';
import { RegisterData } from 'typings/api/user';
import { ErrorResponse } from 'typings/api/common';

import { registerFormFields } from 'constants/authFormFields';

import { useRegisterUserMutation } from 'api/usersSlice';

import FormField from 'components/form/FormField';
import Button from 'components/common/Button';
import AuthBase from './AuthBase';
import ErrorBlock from 'components/common/ErrorBlock';

const RegisterForm: ReactComponent = () => {
  const methods = useForm();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [regiserUser, status] = useRegisterUserMutation();
  const { isError, isSuccess, isLoading, error, data } = status;

  const registerUser = (data: FieldValues) => {
    regiserUser(data as RegisterData).unwrap();
  };

  return (
    <FormProvider {...methods}>
      <AuthBase
        type="register"
        onSubmit={registerUser}
        isDisabled={isLoading}
        buttonsHidden={isSuccess}
      >
        {isError && <ErrorBlock errors={error as ErrorResponse} />}
        {isSuccess ? (
          <>
            <p className="text-center text-success text-md">{data.message}</p>
            <Button onClick={() => navigate('/login')}>
              {t('user.register.backToLogin')}
            </Button>
          </>
        ) : (
          registerFormFields.map(({ name, label, type, required }, idx) => (
            <FormField
              key={`register-field-${idx}`}
              type={type}
              label={label}
              name={name}
              register={methods.register}
              required={required}
            />
          ))
        )}
      </AuthBase>
    </FormProvider>
  );
};

export default RegisterForm;
