import { FieldError, useFormContext } from 'react-hook-form';

import { useGetSelectOptionsQuery } from 'api/apiSlice';

import { ReactComponent } from 'typings/common/react';
import { SelectOption } from 'typings/form/form';

import { compareValues } from 'util/tableHelpers';

import MultiSelect from './MultiSelect';
import SimpleSelect from './SimpleSelect';

import RestSelect from "./RestSelect";

interface Props {
  name: string;
  isMulti: boolean;
  label: string;
  required: boolean;
  searchUrl: string;
  installationId: number;
  options?: SelectOption[];
  creatable?: boolean;
  isDisabled?: boolean;
  error?: FieldError;
  blacklistedValues?: number[];
  isAsync: Boolean;
}

const Select: ReactComponent<Props> = ({
  name,
  isMulti,
  searchUrl,
  installationId,
  required,
  label,
  error,
  options = [],
  blacklistedValues = [],
  creatable = false,
  isDisabled = false,
  isAsync= false,
}) => {
  const { data } = useGetSelectOptionsQuery(
    {
      installationId,
      endpoint: searchUrl,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !searchUrl,
    }
  );
  const { control, setValue, getValues } = useFormContext();
  const removeBlacklistedValuesFromData = () => {
    if (blacklistedValues instanceof Array) {
      return data?.filter(
        item => item?.value && !blacklistedValues.includes(item.value)
      );
    }
    return data;
  };

  const optionsData = [
    ...(options.length ? options : removeBlacklistedValuesFromData() || []),
  ]?.sort((option1, option2) =>
    compareValues(option1?.label || '', option2?.label || '')
  );
  return isMulti ? (
    <MultiSelect
      name={name}
      data={optionsData || []}
      label={label}
      control={control}
      setValue={setValue}
      getValues={getValues}
      required={required}
      disabled={!creatable && (!optionsData?.length || isDisabled)}
      creatable={creatable}
      error={error}
    />
    ) : (isAsync ? (
        <RestSelect
            name={name}
            required={required}
            label={label}
            data={optionsData || []}
            control={control}
            setValue={setValue}
            getValues={getValues}
            installationId={installationId}
        />) :
      (
      <SimpleSelect
          name={name}
          data={optionsData || []}
          required={required}
          label={label}
          control={control}
          setValue={setValue}
          getValues={getValues}
          disabled={isDisabled || !optionsData?.length}
          error={error}
      />
      )
    );
};

export default Select;
