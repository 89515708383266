import { apiSlice } from './apiSlice';

import { DefaultQueryParams, GenericResponse } from 'typings/api/common';
import { Log } from 'typings/api/logs';

const logsSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getLogs: builder.query<GenericResponse<Log[]>, DefaultQueryParams>({
      query: ({ id, search, page }) => ({
        url: `/logs`,
        method: 'GET',
        params: { installation: id, search, page },
      }),
      providesTags: ['Log'],
    }),
  }),
});

export const { useGetLogsQuery } = logsSlice;
