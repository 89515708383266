import clsx from 'clsx';
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form';

import { ClassNamesProp, ReactComponent } from 'typings/common/react';

import FormLabel from './FormLabel';

interface Props extends ClassNamesProp {
  name: string;
  label?: string | null;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
  isDisabled?: boolean;
  error?: FieldError;
}

const Checkbox: ReactComponent<Props> = ({
  name,
  label,
  register,
  error,
  className,
  required = false,
  isDisabled = false,
}) => {
  const classes = clsx(
    className || 'w-4 h-4 rounded bg-white transition-colors duration-300',
    {
      'bg-gray-100 border-purple-disabled text-purple-disabled': isDisabled,
      'bg-white border-purple-light text-purple-light': !isDisabled && !error,
      'border-2 border-danger-light': error,
    }
  );
  return (
    <div className="inline-flex items-center mx-1 space-x-1">
      <input
        {...register(name)}
        type="checkbox"
        className={classes}
        disabled={isDisabled}
        id={name}
      />
      <FormLabel required={required} inputId={name}>
        {label}
      </FormLabel>
      {error?.message && (
        <p className="text-sm text-danger-light">{error.message}</p>
      )}
    </div>
  );
};

export default Checkbox;
