import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from 'api/apiSlice';
import { rtkQueryResponseMiddleware } from 'middleware/rtkMiddleware';

import authReducer from 'store/authSlice';
import configReducer from 'store/configsSlice';

export const store = configureStore({
  reducer: {
    configs: configReducer,
    authUser: authReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMidleware =>
    getDefaultMidleware({}).concat([
      apiSlice.middleware,
      rtkQueryResponseMiddleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
