export const calculateFirstVisiblePage = (
  lastPage: number,
  currentPage: number,
  delta: number = 2
) => {
  if (currentPage === 1) return currentPage;
  const minPage = lastPage - delta * 2;
  const page = Math.min(currentPage - delta, minPage);

  return page <= 0 ? 1 : page;
};

export const calculateLastVisiblePage = (
  totalPages: number,
  currentPage: number,
  delta: number
) => {
  if (currentPage === totalPages) return currentPage;
  const maxPage = delta * 2 + 1;
  const page = Math.max(currentPage + delta, maxPage);
  return page > totalPages ? totalPages : page;
};
