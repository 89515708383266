import clsx from 'clsx';
import { Combobox, Transition } from '@headlessui/react';
import { useState } from 'react';
import {
  Control,
  FieldError,
  FieldValues,
  useController,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent } from 'typings/common/react';
import { SelectOption } from 'typings/form/form';

import { filterQueryResults } from 'util/tableHelpers';

import Button from 'components/common/Button';
import { ReactComponent as CrossIcon } from 'assets/svg/x-lg.svg';
import { ReactComponent as ChevornDown } from 'assets/svg/chevron-down.svg';
import FormLabel from './FormLabel';

interface Props {
  name: string;
  data: SelectOption[];
  required?: boolean;
  label?: string | null;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  disabled?: boolean;
  creatable?: boolean;
  error?: FieldError;
}

const MultiSelect: ReactComponent<Props> = ({
  name,
  label,
  required,
  data,
  setValue,
  getValues,
  control,
  creatable,
  error,
  disabled = false,
}) => {
  const [query, setQuery] = useState('');
  const { t } = useTranslation();

  const filteredResults = !query ? data : filterQueryResults(data, query);
  const {
    field: { value, onChange },
  } = useController({ name, control });

  const removeItemFromValues = (id: number | null) => {
    const values = getValues(name);
    const filteredValues = values?.filter(
      (item: SelectOption) => item.value !== id
    );
    setValue(name, filteredValues, { shouldDirty: true });
  };

  const customOnChange = (e: Event) => {
    setQuery('');
    onChange(e);
  };

  const clearSelectedButtonClasses = clsx({
    'text-danger-light': !disabled,
    'text-danger-disabled': disabled,
  });

  const comboBoxButtonClasses = clsx({
    'text-purple-light': !disabled,
    'text-purple-disabled': disabled,
  });

  const selectedOptionsClasses = clsx(
    'border border-t-0 rounded-bl rounded-br flex-wrap ',
    {
      'bg-white border-purple-light': !disabled && !error,
      'bg-gray-100 border-purple-disabled': disabled,
      'border-danger-light': error,
    }
  );

  const removeOptionButtonClasses = clsx('inline-flex items-center', {
    'hover:text-danger-light duration-300': !disabled,
    'text-gray-100': disabled,
  });

  const selectedOptionClasses = clsx(
    'inline-flex items-center m-1 px-2 py-1 rounded w-fit gap-1',
    {
      'text-white bg-purple-light': !disabled,
      'bg-purple-disabled text-gray-100': disabled,
    }
  );
  const selectInputClasses = clsx(
    'w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 h-8',
    {
      'border-b-0 rounded-tr rounded-tl border-purple-light':
        value?.length > 0 && !error,
      'border-purple-light rounded': !error && (!value || value?.length === 0),
      'bg-gray-100 border-purple-disabled': disabled,
      'border-2 border-danger-light rounded': error,
    }
  );

  return (
    <div className="w-56 justify-end">
      {label && (
        <FormLabel inputId={name} required={required}>
          {label}
        </FormLabel>
      )}

      <Combobox
        as="div"
        multiple
        onChange={customOnChange}
        value={value}
        disabled={disabled}
      >
        <span className="rounded-md shadow-sm relative">
          <Combobox.Input
            onChange={event => setQuery(event.target?.value)}
            value={query}
            displayValue={(value: SelectOption) => value?.label ?? ''}
            className={selectInputClasses}
            disabled={disabled}
            placeholder={t('global.search')}
            id={name}
          />

          <div className="absolute inset-y-0 right-0 flex items-center gap-2 pr-2">
            <Button
              className={clearSelectedButtonClasses}
              onClick={() => {
                setValue(name, [], { shouldDirty: true });
                setQuery('');
              }}
              disabled={disabled}
            >
              <CrossIcon />
            </Button>
            <Combobox.Button className={comboBoxButtonClasses}>
              <ChevornDown />
            </Combobox.Button>
          </div>
        </span>
        {value?.length > 0 && (
          <div className={selectedOptionsClasses}>
            {value?.map((item: SelectOption, idx: number) => (
              <div className={selectedOptionClasses} key={`selected-${idx}`}>
                <p className="text-sm">{item?.label}</p>
                <Button
                  className={removeOptionButtonClasses}
                  disabled={disabled}
                  onClick={() => removeItemFromValues(item?.value || null)}
                >
                  <CrossIcon />
                </Button>
              </div>
            ))}
          </div>
        )}
        <Transition
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="relative rounded-md bg-white shadow-lg z-50"
        >
          <Combobox.Options className="max-h-60 rounded py-1 shadow-md overflow-x-auto focus:outline-none text-sm leading-5 w-56 bg-white absolute">
            {creatable && !filteredResults.length && (
              <Button
                className="text-center w-full hover:bg-pink py-2"
                onClick={() => {
                  const newOption = {
                    value: data.length + 1,
                    label: query,
                  };
                  onChange([...value, newOption]);
                  data.push(newOption);
                  setQuery('');
                }}
              >
                {t('selected.add_new', { query })}
              </Button>
            )}
            {((filteredResults?.length === 0 && query !== '') ||
              filteredResults?.length === 0) &&
              !creatable && (
                <div className="cursor-default select-none py-2 px-4 text-center">
                  {t('global.nothing_found')}
                </div>
              )}
            {filteredResults?.length > 0 &&
              filteredResults?.map(item => {
                const isDisabled = value?.find(
                  (value: SelectOption) => value?.value === item?.value
                );
                const optionClasses = clsx(
                  'select-none py-2 px-4 overflow-x hover:bg-pink',
                  {
                    'bg-pink cursor-not-allowed': isDisabled,
                    'bg-white cursor-pointer': !isDisabled,
                  }
                );

                return (
                  <Combobox.Option
                    key={item?.value}
                    className={optionClasses}
                    value={item}
                    as="p"
                    disabled={isDisabled}
                  >
                    {item?.label}
                  </Combobox.Option>
                );
              })}
          </Combobox.Options>
        </Transition>
      </Combobox>
      {error?.message && (
        <p className="text-sm text-danger-light">{error.message}</p>
      )}
    </div>
  );
};

export default MultiSelect;
