export const jobRunnerColumns = [
  {
    accessor: 'action',
    header: 'jobRunner.action',
    type: 'plain',
  },
  {
    accessor: 'params',
    header: 'jobRunner.params',
    type: 'plain',
  },

  {
    accessor: 'start',
    header: 'jobRunner.started_at',
    type: 'plain',
  },
  {
    accessor: 'end',
    header: 'jobRunner.finished_at',
    type: 'plain',
  },
  {
    accessor: 'ran_for',
    header: 'jobRunner.ran_for',
    type: 'plain',
  },
  {
    accessor: 'user.name',
    header: 'jobRunner.user',
    type: 'plain',
  },
  {
    accessor: 'error',
    header: 'jobRunner.error',
    type: 'plain',
  },
  {
    accessor: 'status',
    header: 'jobRunner.status',
    type: 'status',
  },
];
