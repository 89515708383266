import { ErrorResponse } from 'typings/api/common';
import { ReactComponent } from 'typings/common/react';

interface Props {
  errors: ErrorResponse;
  type?: 'block' | 'toast';
}
const ErrorBlock: ReactComponent<Props> = ({ errors, type = 'block' }) => {
  return type === 'block' ? (
    <div className="text-center text-danger-light text-md space-y-1">
      {errors.data.errors ? (
        Object.keys(errors.data.errors).map(key =>
          errors.data.errors?.[key].map((message, idx) => (
            <p key={`err-message-${idx}`}>{message}</p>
          ))
        )
      ) : (
        <p>{errors.data.message}</p>
      )}
    </div>
  ) : null;
};

export default ErrorBlock;
