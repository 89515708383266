import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { ReactComponent } from 'typings/common/react';
import { ChangePasswordData, RegisterData } from 'typings/api/user';
import { ErrorResponse } from 'typings/api/common';

import {
  changePasswordFields,
  resetPasswordFields,
} from 'constants/authFormFields';

import {
  useChangePasswordMutation,
  useResetPasswordMutation,
} from 'api/usersSlice';

import FormField from 'components/form/FormField';
import Button from 'components/common/Button';
import ErrorBlock from 'components/common/ErrorBlock';
import AuthBase from './AuthBase';

const ResetPassword: ReactComponent = () => {
  const methods = useForm();
  const { t } = useTranslation();

  const [resetPassword, resetStatus] = useResetPasswordMutation();
  const [changePassword, changeStatus] = useChangePasswordMutation();
  const navigate = useNavigate();

  const { token } = useParams();

  const { isLoading, isError, isSuccess, error, data } = resetStatus;
  const {
    isLoading: isChangeLoading,
    isError: isChangeError,
    isSuccess: isChangeSuccess,
    error: changeError,
    data: changeData,
  } = changeStatus;

  const { search } = useLocation();
  const queryObject = queryString.parse(search);

  const onResetPassword = async (data: FieldValues) => {
    if (token && queryObject?.email) {
      await changePassword({
        ...(data as ChangePasswordData),
        email: queryObject?.email.toString(),
        token,
      });
    } else {
      resetPassword(data as RegisterData).unwrap();
    }
  };

  return (
    <FormProvider {...methods}>
      <AuthBase
        type="resetPassword"
        onSubmit={onResetPassword}
        isDisabled={isLoading || isChangeLoading}
        buttonsHidden={isSuccess || isChangeSuccess}
      >
        {isError && <ErrorBlock errors={error as ErrorResponse} />}
        {isChangeError && <ErrorBlock errors={changeError as ErrorResponse} />}
        {isSuccess && (
          <>
            <p className="text-center text-success text-md">{data.message}</p>
            <Button onClick={() => navigate('/login')}>
              {t('global.confirm')}
            </Button>
          </>
        )}
        {isChangeSuccess && (
          <>
            <p className="text-center text-success text-md">
              {changeData.message}
            </p>
            <Button onClick={() => navigate('/login')}>
              {t('user.register.backToLogin')}
            </Button>
          </>
        )}
        {!isSuccess &&
          !isChangeSuccess &&
          (token && queryObject?.email
            ? changePasswordFields.map(({ name, label, type }, idx) => (
                <FormField
                  key={`reset-field-${idx}`}
                  type={type}
                  label={label}
                  name={name}
                  register={methods.register}
                />
              ))
            : resetPasswordFields.map(({ name, label, type }, idx) => (
                <FormField
                  key={`change-field-${idx}`}
                  type={type}
                  label={label}
                  name={name}
                  register={methods.register}
                />
              )))}
      </AuthBase>
    </FormProvider>
  );
};

export default ResetPassword;
