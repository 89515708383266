import { ClientUpdatePayload } from 'typings/api/clients';
import { InvertedGroup } from 'typings/api/groups';
import { Dictionary } from 'typings/common/react';
import { GroupsOrBrandsPayload } from 'typings/form/form';

export const findErrorsfromBrandsPayload = (
  errors: Dictionary<string[]>,
  payload: GroupsOrBrandsPayload
) => {
  if (errors && payload.length) {
    const allErrors = Object.keys(errors).map(error => {
      const errorItems = error.replace('.installations.0', '').split('.');

      const [groupName, index, fieldName] = errorItems;
      const payloadItem = payload[parseInt(index)];
      const errorMessage = errors[error][0];

      if (fieldName === 'config') {
        return setConfigErrors(payloadItem as InvertedGroup, errorMessage);
      }

      return {
        [`${groupName}.${payloadItem.index}.${fieldName}`]: errorMessage,
      };
    });

    return allErrors.filter(item => !!item).flat(1);
  }

  return [];
};

export const findErrorsFromClientsPayload = (
  errors: Dictionary<string[]>,
  payload: ClientUpdatePayload[]
) => {
  const allErrors = Object.keys(errors).map(error => {
    const errorItems = error.split('.');
    const payloadIndex = parseInt(errorItems[1]);
    if (error) {
      return {
        [`clients.${payload[payloadIndex]?.index}.account_id`]:
          errors[error][0],
      };
    }
    return null;
  });
  return allErrors.filter(item => !!item).flat(1);
};

const setConfigErrors = (configField: InvertedGroup, errorMessage: string) => {
  let errorFields = [];
  if (!configField.installations?.[0].config?.length) {
    return [
      {
        [`groups.${configField.index}.config.0`]: errorMessage,
      },
      {
        [`groups.${configField.index}.config.1`]: errorMessage,
      },
    ];
  }
  if (!configField.installations?.[0].config[1]) {
    errorFields.push({
      [`groups.${configField.index}.config.1`]: errorMessage,
    });
  }
  if (!configField.installations?.[0].config[0]) {
    errorFields.push({
      [`groups.${configField.index}.config.0`]: errorMessage,
    });
  }
  return errorFields;
};
