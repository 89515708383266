import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { ReactComponent } from 'typings/common/react';

import abestoreLogo from 'assets/images/abestore.png';

import Button from 'components/common/Button';

interface Props {
  type?: 'login' | 'register' | 'resetPassword';
  isDisabled: boolean;
  onSubmit: (data: FieldValues) => void;
  buttonsHidden?: boolean;
}

const AuthBase: ReactComponent<Props> = ({
  children,
  onSubmit,
  isDisabled,
  type = 'login',
  buttonsHidden = false,
}) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 bg-gray-100 h-screen sm:px-0 px-6">
      <div className="bg-pink min-h-2/3 lg:col-start-2 place-self-center py-8 w-full sm:w-[26.25rem]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col sm:mx-16 mx-6 mb-6 gap-4">
            <img src={abestoreLogo} alt="logo" className="place-self-center" />
            {children}
            {!buttonsHidden && (
              <Button type="submit" disabled={isDisabled}>
                {t(`user.${type}`)}
              </Button>
            )}
          </div>
        </form>
        {!buttonsHidden && (
          <div className="flex flex-col items-center gap-y-2">
            {type === 'login' ? (
              <>
                <Link
                  className="text-orange text-md hover:underline"
                  to="/reset-password"
                >
                  {t('user.forgotPassword')}
                </Link>
                <Link
                  to="/register"
                  className="text-orange text-md hover:underline"
                >
                  {t('user.register')}
                </Link>
              </>
            ) : (
              <Link to="/login" className="text-orange text-md hover:underline">
                {t('user.login')}
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthBase;
