import clsx from 'clsx';
import { ReactComponent } from 'typings/common/react';

interface Props {
  status: 'QUEUED' | 'RUNNING' | 'FAILED' | 'DONE';
}

const StatusBadge: ReactComponent<Props> = ({ status, children }) => {
  const classes = clsx('text-xs font-medium mr-2 px-2.5 py-0.5 rounded', {
    'bg-red-100 text-red-800': status === 'FAILED',
    'bg-green-100 text-green-800': status === 'DONE',
    'bg-blue-100 text-blue-800': status === 'RUNNING',
    'bg-yellow-100 text-yellow-800': status === 'QUEUED',
  });

  return <span className={classes}>{children}</span>;
};

export default StatusBadge;
