import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ReactComponent } from 'typings/common/react';
import { ResponseMeta } from 'typings/api/common';

import {
  calculateFirstVisiblePage,
  calculateLastVisiblePage,
} from 'util/pagination';

import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'assets/svg/chevron-left.svg';
import { ReactComponent as ChevronDoubleLeft } from 'assets/svg/chevron-double-left.svg';
import { ReactComponent as ChevronDoubleRight } from 'assets/svg/chevron-double-right.svg';

import Button from 'components/common/Button';

interface Props {
  pageIndex: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  pageCount: number;
  nextPage: () => void;
  previousPage: () => void;
  gotoPage: (pageIndex: number) => void;
  meta: ResponseMeta;
}

const Pagination: ReactComponent<Props> = ({
  pageIndex,
  canNextPage,
  canPreviousPage,
  nextPage,
  previousPage,
  pageCount,
  gotoPage,
  meta,
}) => {
  const { t } = useTranslation();
  const pageDelta = 2;
  const currentPage = pageIndex + 1;

  const firstVisiblePage = calculateFirstVisiblePage(
    pageCount,
    currentPage,
    pageDelta
  );
  const lastVisiblePage = calculateLastVisiblePage(
    pageCount,
    currentPage,
    pageDelta
  );

  return (
    <div className="flex justify-center pb-24">
      <div className="inline-flex items-center">
        <Button
          className="px-2 text-gray-300 hover:underline"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <ChevronDoubleLeft />
        </Button>
        <Button
          className="px-2 text-gray-300 hover:underline"
          disabled={!canPreviousPage}
          onClick={previousPage}
        >
          <ChevronLeft />
        </Button>
        {Array(lastVisiblePage - firstVisiblePage + 1)
          .fill(0)
          .map((_, idx) => {
            const page = firstVisiblePage + idx;
            const className = clsx({
              'px-2 text-gray-300 hover:underline': page !== currentPage,
              'px-2 text-gray-300 underline hover:cursor-default':
                page === currentPage,
            });
            return (
              <Button
                className={className}
                onClick={() => gotoPage(page - 1)}
                disabled={page === currentPage}
                key={`pagination-${idx}`}
              >
                {page}
              </Button>
            );
          })}
        {pageIndex < pageCount - pageDelta - 1 && (
          <>
            ...
            <Button
              className="px-2 text-gray-300 hover:underline"
              onClick={() => gotoPage(pageCount - 1)}
            >
              {pageCount}
            </Button>
          </>
        )}
        <Button
          className="px-2 text-gray-300 hover:underline"
          disabled={!canNextPage}
          onClick={nextPage}
        >
          <ChevronRight />
        </Button>
        <Button
          className="px-2 text-gray-300 hover:underline"
          disabled={!canNextPage}
          onClick={() => gotoPage(pageCount - 1)}
        >
          <ChevronDoubleRight />
        </Button>
      </div>
      <div className="text-gray-200 ml-4">
        {t('pagination.total', {
          from: meta.from,
          to: meta.to,
          total: meta.total,
        })}
      </div>
    </div>
  );
};

export default Pagination;
