import {ReactComponent} from "../../typings/common/react";
import {Control, FieldValues, useController, UseFormGetValues, UseFormSetValue} from "react-hook-form";
import AsyncSelect from 'react-select/async';
import {useAppSelector} from "../../util/hooks/hooks";
import {SelectOption} from "../../typings/form/form";

interface Props{
    name: string;
    label?: string | null;
    required?: boolean;
    data: SelectOption[];
    control: Control<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    installationId: number;
}

const RestSelect: ReactComponent<Props> = ({
    name,
    label,
    required,
    control,
    data,
    setValue,
    getValues,
    installationId
})=> {
    const { access_token } = useAppSelector(state => state.authUser);

    const fieldDefaultValue = () => {
        const arr = name.toString().split(".");
        const labelKey = arr[0] + '.' + arr[1] + '.label'
        return {label:getValues(labelKey),value:getValues(name)};
    }
    const defaultOptions = () => {
        const arr = name.toString().split(".");
        const labelKey = arr[0] + '.' + arr[1] + '.label'
        return [{label:getValues(labelKey),value:getValues(name)}];
    }

    const {
        field: { value, onChange },
    } = useController({ name, control, defaultValue: fieldDefaultValue() });

    const promiseOptions = async (value: string) => {
        return await fetch(
            `${process.env.REACT_APP_API_URL}clients/${installationId}/select?search=${value}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${access_token}`,
                },
            }
        )
            .then(response => response.json())
            .then(final =>
                final.filter((i: SelectOption) =>
                    i.label?.toLowerCase().includes(value.toLowerCase())
                )
            );
    }
    const set = (value : number|Object) => {

        if(typeof value === 'number'){
            const arr = name.toString().split(".");
            const labelKey = arr[0] + '.' + arr[1] + '.label'
            return {label:getValues(labelKey),value:value};
        }
        return value;
    }

    return (
        <AsyncSelect
            loadOptions={promiseOptions}
            onChange={onChange}
            value={set(value)}
            defaultOptions={defaultOptions()}
            defaultValue={fieldDefaultValue()}
            isClearable={true}
        />
    )
};

export default RestSelect;