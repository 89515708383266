import {
  LoginResponse,
  LoginData,
  RegisterResponse,
  RegisterData,
  ResetPasswordResponse,
  ResetPasswordData,
  ChangePasswordResponse,
  ChangePasswordData,
  LogoutResponse,
  LogoutData,
  RefreshTokenResponse,
} from 'typings/api/user';
import { apiSlice } from './apiSlice';

export const usersSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    loginUser: builder.mutation<LoginResponse, LoginData>({
      query: payload => ({
        url: '/login',
        method: 'POST',
        body: payload,
      }),
    }),
    registerUser: builder.mutation<RegisterResponse, RegisterData>({
      query: payload => ({ url: '/register', method: 'POST', body: payload }),
    }),
    resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordData>({
      query: payload => ({
        url: '/forgot-password',
        method: 'POST',
        body: payload,
      }),
    }),
    changePassword: builder.mutation<
      ChangePasswordResponse,
      ChangePasswordData
    >({
      query: payload => ({
        url: `/change-password/${payload.token}?email=${payload.email}`,
        method: 'POST',
        body: {
          password: payload.password,
          password_confirmation: payload.password_confirmation,
        },
      }),
    }),
    refreshToken: builder.mutation<RefreshTokenResponse, void>({
      query: () => ({
        url: `/refresh`,
        method: 'POST',
      }),
    }),
    logout: builder.mutation<LogoutResponse, LogoutData>({
      query: payload => ({
        url: '/logout',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${payload.token}`,
        },
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useLogoutMutation,
  useRefreshTokenMutation,
} = usersSlice;
