import { useTranslation } from 'react-i18next';
import { ReactComponent } from 'typings/common/react';

import Button from 'components/common/Button';

interface Props {
  disabled: boolean;
  checkAction?: () => void;
  checkActionName?: string;
}

const TableButtons: ReactComponent<Props> = ({
  disabled,
  checkAction,
  checkActionName,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex lg:absolute lg:top-0 lg:right-0 lg:pt-12 lg:flex-row flex-col gap-4">
      <Button type="submit" disabled={disabled}>
        {t('actions.common.saveChanges')}
      </Button>
      {checkAction && checkActionName && (
        <Button onClick={() => checkAction()} disabled={disabled}>
          {t(checkActionName)}
        </Button>
      )}
    </div>
  );
};

export default TableButtons;
