import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { configurationSlice } from 'api/configurationSlice';
import { GenericResponse } from 'typings/api/common';
import { ConfigurationGroup } from 'typings/api/configurations';

interface InitialState {
  configurations: ConfigurationGroup[];
}

const initialState: InitialState = {
  configurations: [],
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setConfigurations(state, { payload }: PayloadAction<ConfigurationGroup[]>) {
      state.configurations = payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      configurationSlice.endpoints.getConfigurations.matchFulfilled,
      (
        state,
        { payload }: PayloadAction<GenericResponse<ConfigurationGroup[]>>
      ) => {
        state.configurations = payload.data;
      }
    );
  },
});

export const { setConfigurations } = slice.actions;

export default slice.reducer;
